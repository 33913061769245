import { PartnershipData } from '@mc/api'

import { convertKeysToCamelCase } from '../../keysToCamelCase'
import { getAccountPropertiesAsync } from './getAccountPropertiesAsync'

export default async function getPartnershipProperties(): Promise<
  PartnershipData | Record<string, never>
> {
  const { partnershipsData } = await getAccountPropertiesAsync()

  return partnershipsData ? convertKeysToCamelCase(partnershipsData) : {}
}
