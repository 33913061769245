import type { ObjectWithIntegerIdArray } from '@mc/api'
import { getDataFromHeadTag } from '@mc/html-data-attr'
import { convertKeysToCamelCase, KeysToCamelCase } from '../../keysToCamelCase'
import isLoggedIn from '../interactors/isLoggedIn'

interface ProfileHeadTagProps {
  id: number
  uuid: string
  user_id: number
  slug: string

  created_at: string
  updated_at: string | null
  deleted_at: null

  first_name?: string
  last_name?: string | null
  display_name?: string | null

  default_profile: boolean
  avatar_color?: string | null
  profile_photo_url?: string | null
  profile_image_url?: string
  profile_background_id?: string | null

  mature_content_enabled?: boolean | null
  tagline?: string
  location?: string
  city: string | null
  skills: ObjectWithIntegerIdArray
  country: string | null
  personal_url_type?: string | null
  personal_url?: string | null
  profession?: string | null
}

export type ProfileProps = KeysToCamelCase<ProfileHeadTagProps>

let profile: ProfileProps | undefined

const initializeProfile = (): ProfileProps | undefined => {
  if (!isLoggedIn()) {
    return undefined
  }

  const profileProps = getDataFromHeadTag('profile') as ProfileHeadTagProps

  return convertKeysToCamelCase(profileProps)
}

export const getCurrentProfile = (): ProfileProps | undefined => {
  if (!profile) {
    profile = initializeProfile()
  }

  return profile ? { ...profile } : undefined
}

export const updateProfile = (profileUpdates: Partial<ProfileProps>) => {
  if (!profile) {
    profile = initializeProfile()
  }

  if (profile) {
    Object.assign(profile, profileUpdates)
  }
}

export const forceUpdateProfile = (newProfile: ProfileProps) => {
  profile = newProfile
}
