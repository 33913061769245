// Durations
export const ONE_DAY = 60 * 60 * 24
export const ONE_WEEK = 60 * 60 * 24 * 7
export const ONE_MONTH = 60 * 60 * 24 * 30
export const ONE_YEAR = 60 * 60 * 24 * 365

// URLs
export const SITE_URL = 'https://www.masterclass.com'
export const ARTICLE_IMAGE_BASE = 'https://images.ctfassets.net'
export const STATIC_IMAGE_BASE = 'https://static.masterclass.com'
export const CLOUDFLARE_IMAGE_PREFIX =
  'https://www.masterclass.com/cdn-cgi/image'

// Metadata
export const FB_APP_ID = '1438642769784622'
export const OG_IMAGE_HEIGHT = '630'
export const OG_TYPE = 'website'
export const SITE_NAME = 'MasterClass'
