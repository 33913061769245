const DEFAULT_LOCALE = 'en-US'

let availableLocales = [DEFAULT_LOCALE]

export const getLocaleFromUriPath = () => {
  if (
    typeof window !== 'undefined' &&
    typeof window.location !== 'undefined' &&
    typeof window.location.pathname !== 'undefined'
  ) {
    const pathRegex = /\/([a-z]{2}|[a-z]{2}-[A-Z]{2})\//g
    const locale = window.location.pathname.match(pathRegex)
    if (locale instanceof Array && availableLocales.includes(locale[0])) {
      return locale[0]
    }
  }

  return undefined
}

export const getLocalesFromBrowserSettings = () => {
  const localesFound = []

  if (typeof navigator !== 'undefined') {
    if (navigator.languages) {
      for (let i = 0; i < navigator.languages.length; i += 1) {
        localesFound.push(navigator.languages[i])
      }
    }

    // IE only
    // TODO: Do we need this?
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof navigator.userLanguage !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      localesFound.push(navigator.userLanguage)
    }

    if (navigator.language) {
      localesFound.push(navigator.language)
    }
  }

  const uniqueLocales = localesFound.filter(
    (value, index, self) => self.indexOf(value) === index,
  )

  return uniqueLocales
}

export const getBrowserLocale = () =>
  getLocalesFromBrowserSettings()
    .filter((locale) => availableLocales.includes(locale))
    .shift()

let currentLocale: string = DEFAULT_LOCALE

export const getCurrentLocale = () => currentLocale

export const forceUpdateCurrentLocale = (locale: string) => {
  currentLocale = locale
}

export const getAvailableLocales = () => availableLocales

export const forceUpdateAvailableLocales = (locales: string[]) => {
  availableLocales = locales
}
