import {
  GetServerSidePropsContext,
  NextApiRequest,
  NextApiResponse,
} from 'next'
import Cookies from 'cookie-universal'
import { getSession } from '@auth0/nextjs-auth0'

import {
  AI_PERSONA_REFRESH_TOKEN_COOKIE_NAME,
  AI_PERSONA_SESSION_COOKIE_NAME,
  paths,
} from '@mc/persona-client'

import { setAuthHeaders } from './setAuthHeaders'

export const getOrRefreshAccessToken = async ({
  req,
  res,
  isMVPEnabled = true,
}: {
  req: NextApiRequest | GetServerSidePropsContext['req']
  res: NextApiResponse | GetServerSidePropsContext['res']
  isMVPEnabled?: boolean
}) => {
  const cookies = Cookies(req, res)
  let accessToken
  let refreshToken
  let expiresAt
  if (isMVPEnabled) {
    accessToken = cookies.get(AI_PERSONA_SESSION_COOKIE_NAME)
    refreshToken = cookies.get(AI_PERSONA_REFRESH_TOKEN_COOKIE_NAME)
  } else {
    const session = await getSession(req, res)
    if (session && session.accessToken) {
      accessToken = session.accessToken
      refreshToken = session.refreshToken
      expiresAt = session.accessTokenExpiresAt
    }
  }

  if (accessToken) {
    // Decode the access token to check expiration timestamp
    const tokenPayload = JSON.parse(
      Buffer.from(accessToken.split('.')[1], 'base64').toString(),
    )
    // if MVP is on, expiresAt is undefined but tokenPayload.exp is defined
    expiresAt = expiresAt || tokenPayload.exp
    // current server time timestamp
    const currentTime = Math.floor(Date.now() / 1000)
    // Token is expired, use the refresh token to get a new one
    if (expiresAt < currentTime) {
      // If there is no refresh token, delete the access token as is not valid anymore
      if (!refreshToken) {
        if (isMVPEnabled) {
          setAuthHeaders({ res, accessToken: '', idToken: '', expiresIn: 0 })
        } else {
          // redirect to logout
          res.writeHead(302, { Location: paths.personaAuth('logout') })
        }
        return null
      }

      // Call Auth0 to get a new access token using the refresh token
      const response = await fetch(
        `${process.env.AUTH0_ISSUER_BASE_URL}/oauth/token`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            grant_type: 'refresh_token',
            client_id: process.env.AUTH0_CLIENT_ID,
            client_secret: process.env.AUTH0_CLIENT_SECRET,
            refresh_token: refreshToken,
          }),
        },
      )

      if (response.ok) {
        const refreshedTokens = await response.json()
        // Update the session with the new access token and expiration
        accessToken = refreshedTokens.access_token
        // Set the new access token in the cookies
        if (isMVPEnabled) {
          setAuthHeaders({
            res,
            accessToken,
            idToken: refreshedTokens.id_token,
            expiresIn: refreshedTokens.expires_in,
            refreshToken: refreshedTokens.refresh_token,
          })
        }
      }
    }
  }
  return accessToken
}
