import React, { ErrorInfo, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { isDevelopmentEnv } from '@mc/client-env'
import { reportError } from '@mc/monitoring'

import classes from './ErrorBoundary.module.scss'

interface Props {
  onError?(error: Error, errorInfo: ErrorInfo): void
  fallback?(): JSX.Element | string
  children?: React.ReactNode
}

interface State {
  error: Error | null
  errorInfo: ErrorInfo | null
}

export class ErrorBoundary extends PureComponent<Props, State> {
  state: State = {
    error: null,
    errorInfo: null,
  }

  static propTypes = {
    children: PropTypes.node,
    fallback: PropTypes.func,
    onError: PropTypes.func,
  }

  static defaultProps = {
    onError: () => {},
  }

  static getDerivedStateFromError(error: Error) {
    return {
      error,
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo })
    reportError(error, {
      componentStack: errorInfo.componentStack ?? '',
      digest: errorInfo.digest ?? '',
    })
    this.props.onError?.(error, errorInfo)
  }

  render() {
    const { error, errorInfo } = this.state
    const { fallback, children } = this.props

    if (!error) return children

    return (
      <>
        {fallback && <ErrorBoundary>{fallback()}</ErrorBoundary>}
        {isDevelopmentEnv() && (
          <div
            className={`${classes.alert} mc-corners--rounded`}
            data-testid='development-error-banner'
          >
            <h2>{error?.toString()}</h2>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {errorInfo?.componentStack}
            </details>
          </div>
        )}
      </>
    )
  }
}

export default ErrorBoundary
