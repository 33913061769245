import { AppContext } from 'next/app'

import initGeoService from '.'

/**
 * A helper to retrieve user geo using the same logic as `initGeoService`
 * easily from `getServerSideProps`
 */
export const getServerGeo = (ctx: AppContext['ctx']) => {
  const { req, res, query } = ctx
  const { geo, region } = initGeoService(req, res, { query })
  return { geo, region }
}
