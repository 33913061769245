import NextHead from 'next/head'
import PropTypes from 'prop-types'

import { childrenType } from 'libs/types'
import { FB_APP_ID, OG_TYPE } from 'libs/constants'

import Consent from './Consent'
import NewRelic from './NewRelic'
import Segment from './Segment'
import { Turnstile } from './Turnstile'

const SCHEMA = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'MasterClass',
  founder: 'David Rogier',
  legalName: 'Yanka Industries inc',
  alternateName: 'Master Class',
  logo: 'https://upload.wikimedia.org/wikipedia/en/thumb/d/d6/MasterClass_Logo.jpg/220px-MasterClass_Logo.jpg',
  url: 'https://www.masterclass.com/',
  sameAs: [
    'https://twitter.com/masterclass',
    'https://en.wikipedia.org/wiki/MasterClass',
    'https://www.facebook.com/MasterClassOfficial/',
    'http://www.linkedin.com/company/masterclassinc',
    'https://www.instagram.com/masterclass/',
    'https://www.youtube.com/c/masterclass',
    'https://www.crunchbase.com/organization/masterclass',
    'https://en.wikipedia.org/wiki/MasterClass',
    'https://play.google.com/store/apps/details?id=com.yanka.mc&hl=en_US',
    'https://apps.apple.com/us/app/masterclass-learn-new-skills/id1273867416',
    'https://www.masterclass.com/help-center',
    'https://www.bloomberg.com/profile/company/1379804D:US',
  ],
  mainEntityOfPage: [
    'https://www.businessinsider.com/masterclass-review',
    'https://www.cbsnews.com/news/masterclass-in-a-class-of-its-own/',
    'https://techcrunch.com/2020/09/23/fundraising-lessons-from-david-rogier-of-masterclass/',
    'https://techcrunch.com/2020/05/20/masterclass-just-raised-100-million-for-celebrity-fueled-content/',
    'https://nymag.com/strategist/article/best-masterclass-classes.html',
    'https://www.pcmag.com/reviews/masterclass',
    'https://techcrunch.com/2018/10/03/masterclass-new-offerings/',
    'https://www.nytimes.com/2020/05/25/style/masterclass-secrets.html',
    'https://www.bloombergquint.com/business/masterclass-is-said-to-seek-funding-at-about-800-million-value',
    'https://www.inc.com/jessica-stillman/masterclass-ceo-joining-an-early-stage-startup-is-less-risky-than-you-think.html',
  ],
  description:
    "MasterClass is the streaming platform where anyone can learn from the world's best across a wide range of subjects.",
}

const Head = ({
  children,
  pageName,
  analyticsLabel,
  analyticsIntegrations,
}: {
  children: React.ReactNode
  pageName?: string
  analyticsLabel?: string
  analyticsIntegrations?: Record<string, boolean>
}) => (
  <>
    <NextHead>
      {children}
      <meta charSet='utf-8' />
      <meta name='viewport' content='initial-scale=1.0, width=device-width' />
      <meta content={FB_APP_ID} property='fb:app_id' />
      <meta content={OG_TYPE} property='og:type' />
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{ __html: JSON.stringify(SCHEMA) }}
      />
    </NextHead>

    <NewRelic />
    <Segment
      analyticsLabel={analyticsLabel ?? pageName}
      analyticsIntegrations={analyticsIntegrations}
    />
    <Consent />
    <Turnstile />
  </>
)

Head.propTypes = {
  children: childrenType,
  pageName: PropTypes.string,
  analyticsLabel: PropTypes.string,
  analyticsIntegrations: PropTypes.objectOf(PropTypes.bool),
}

export default Head
