import Script from 'next/script'
import { reportError } from 'libs/new-relic'
import { useEffect } from 'react'
import { trackExperimentViewed } from '@mc/track-event'
import { useBucket } from '@mc/experiments-react'

// https://app.growthbook.io/features/com-839
const FLAG = 'com-839'

const useTurnstile = () => {
  useEffect(() => {
    trackExperimentViewed(FLAG)
  }, [])
  return useBucket<'variant_1'>(FLAG)
}

export const Turnstile = () => {
  if (useTurnstile() !== 'variant_1') {
    return null
  }
  return (
    <Script
      src='https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit'
      async
      defer
      onError={(e) => {
        // eslint-disable-next-line no-console
        console.error('Turnstile script onError', e)
        reportError(new Error('Turnstile script onError'), {
          errEvent: JSON.stringify(e),
        })
      }}
    />
  )
}
