import { getEnvVar } from '@mc/client-env'

export const log = (event: string, data?: unknown) => {
  if (getEnvVar('LOG_RUM_CALLS')) {
    /* eslint-disable no-console */
    if (data) {
      console.groupCollapsed(`RUM: ${event}`)
      console.info(data)
      console.groupEnd()
    } else {
      console.log(`RUM: ${event}`)
    }
    /* eslint-enable no-console */
  }
}
