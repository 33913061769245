import { getEnvVar } from '@mc/client-env'
import { EventTypes } from './constants'

export const logSegmentCalls = (
  type: string,
  event: string,
  inputProperties?: Record<string, unknown>,
  options?: object,
) => {
  const logEnv = getEnvVar('LOG_SEGMENT_CALLS')
  if (logEnv === true || logEnv === 'true') {
    let output = `analytics.${type}('${event}'`
    if (inputProperties) {
      output += `, ${JSON.stringify(inputProperties, null, 2)}`
    }
    if (options && Object.keys(options).length) {
      output += `, ${JSON.stringify(options, null, 2)}`
    }
    output += ')'

    if (
      event === EventTypes.EXPERIMENT_VIEWED &&
      inputProperties?.experimentName &&
      inputProperties?.variationName
    ) {
      // eslint-disable-next-line no-console
      console.groupCollapsed(
        `Segment event: ${event} (${inputProperties.experimentName}: ${inputProperties.variationName})`,
      )
    } else {
      console.groupCollapsed(`Segment event: ${event}`) // eslint-disable-line
    }

    console.info(`%c ${output}`, 'color: #59a2ea') // eslint-disable-line
    console.groupEnd() // eslint-disable-line
  }
}
