import { useEffect } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { getEnvVar } from '@mc/client-env'
import { loadConsentedSegment as loadCCPASegment } from '@mc/consent-manager-loader'
import { useBucket } from '@mc/experiments-react'

import getDestinationPreferences from './getDestinationPreferences'
import { FB_PIXEL_OPTIONS } from './constants'

declare global {
  function loadGDPRSegment(): Promise<void>
}

global.loadGDPRSegment = async () => {
  const {
    userHasGivenUsPermission,
    destinationPreferences,
    onlyStrictlyNecessary,
  } = await getDestinationPreferences()
  if (userHasGivenUsPermission) {
    // user has accepted all terms
    window.analytics?.load(getEnvVar('SEGMENT_API_KEY') as string, {
      integrations: { 'On Call Facebook Pixel': FB_PIXEL_OPTIONS },
    })
  } else if (!onlyStrictlyNecessary) {
    // user has opted out of certain categories
    window.analytics.load(getEnvVar('SEGMENT_API_KEY') as string, {
      integrations: destinationPreferences,
    })
  }
}

const Consent = () => {
  // Do not show the GDPR consent form when casting to
  // a Chromecast device.
  // Consider creating a new pageSetting param if there
  // are more pages that need to hide consent.
  const { pathname } = useRouter()
  const isChromecastPath = pathname === '/chromecast/receiver'

  const isGDPR = useBucket('gdpr') === 'on'
  const isCCPA = useBucket('ccpa') === 'on'

  useEffect(() => {
    if (isCCPA) {
      // loads segment through airgap when CCPA is active
      loadCCPASegment()
    }
  }, [isCCPA])

  return isGDPR && !isChromecastPath ? (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: `function OptanonWrapper () {
          loadGDPRSegment()
        }`,
        }}
      />
      <script
        async
        data-document-language='true'
        data-domain-script={getEnvVar('ONE_TRUST_DOMAIN_SCRIPT')}
        src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
      />
    </Head>
  ) : null
}

export default Consent
