let region: string

export const setRegion = (nextRegion: string) => {
  region = nextRegion
}

export default function regionRepository() {
  // if (typeof window === 'undefined') {
  //   throw new Error(
  //     'global `region` repository can only be used in the browser',
  //   )
  // }
  return region
}
