import { v1 as uuid } from 'uuid'
import type NewRelicBrowser from 'new-relic-browser'

declare global {
  interface Window {
    newrelic?: typeof NewRelicBrowser
  }
}

const pageViewId = uuid()

export const addPageAction = (
  name: string,
  props?: Record<string, string | number>,
) => {
  window.newrelic?.addPageAction(name, {
    ...props,
    pageViewId,
  })
}

export const reportError = (
  error: Error | string,
  props?: Record<string, string | number>,
) => {
  if (typeof window !== 'undefined') {
    window.newrelic?.noticeError(error, props)
  } else {
    // eslint-disable-next-line no-console
    console.error('Error:', error, props)
  }
}

// This is not currently testable, see: https://github.com/facebook/jest/issues/5620
export const initializeReporting = () => {
  window.addEventListener('unhandledrejection', (event) => {
    reportError(event.reason)
  })
}

export default {
  addPageAction,
  reportError,
}
