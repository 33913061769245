import { getAccountPropertiesAsync } from './getAccountPropertiesAsync'

const isValidUserObj = (userObj: object) =>
  userObj && 'id' in userObj && 'email' in userObj

export const isLoggedInAsync = async (refresh = false) => {
  try {
    return isValidUserObj(await getAccountPropertiesAsync(refresh))
  } catch (err) {
    return false
  }
}
