import { optanonActiveGroups } from './optanonActiveGroups'
import {
  STRICTLY_NECESSARY,
  PERFORMANCE,
  FUNCTIONAL,
  TARGETTING,
} from './constants'

export const userHasGivenGdprPermission = () => {
  const activeGroups = optanonActiveGroups()

  return (
    activeGroups.includes(STRICTLY_NECESSARY) &&
    activeGroups.includes(PERFORMANCE) &&
    activeGroups.includes(FUNCTIONAL) &&
    activeGroups.includes(TARGETTING)
  )
}
