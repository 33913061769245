import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
} from 'react'

import ringing from '../../assets/ringing.mp3'
import hangUp from '../../assets/hang-up.mp3'
import thinking from '../../assets/bot-think.mp3'

interface SoundsContextType {
  playRing: (callback?: () => void) => void
  playHangUp: () => void
  playThinking: () => void
}

const DEFAULT_VALUES: SoundsContextType = {
  playRing: (callback?: () => void) => {
    callback?.()
  },
  playHangUp: () => {},
  playThinking: () => {},
}

export const SoundsContext = createContext<SoundsContextType>(DEFAULT_VALUES)

export const usePlaySounds = () => useContext(SoundsContext)

export const SoundsProvider = ({ children }: { children: ReactNode }) => {
  const audioContextRef = useRef<AudioContext | null>(null)
  const gainNodeRef = useRef<GainNode | null>(null)
  const currentAudioRef = useRef<HTMLAudioElement | null>(null)
  const currentSourceRef = useRef<MediaElementAudioSourceNode | null>(null)
  const isConnectedRef = useRef<boolean>(false)

  useEffect(() => {
    const AudioContextConstructor =
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.AudioContext || (window as any).webkitAudioContext
    const context = new AudioContextConstructor()
    const node = context.createGain()
    node.gain.value = 0.5
    node.connect(context.destination)

    audioContextRef.current = context
    gainNodeRef.current = node

    return () => {
      if (context) {
        context.close().catch((err) => {
          reportError(err)
          // eslint-disable-next-line no-console
          console.error('Error closing AudioContext:', err)
        })
      }
    }
  }, [])

  const play = ({
    audio,
    duration,
    callback,
    volume = 0.5,
  }: {
    audio?: HTMLAudioElement | null
    duration?: number
    callback?: () => void
    volume?: number
  }) => {
    try {
      const context = audioContextRef.current
      const node = gainNodeRef.current

      if (context && node && audio) {
        // Clean up previous audio
        if (currentAudioRef.current) {
          currentAudioRef.current.pause()
          if (currentSourceRef.current && isConnectedRef.current) {
            currentSourceRef.current.disconnect(node)
            isConnectedRef.current = false
          }
        }

        const source = context.createMediaElementSource(audio)
        source.connect(node)
        currentSourceRef.current = source
        currentAudioRef.current = audio
        isConnectedRef.current = true

        node.gain.value = volume // Set the volume
        audio.currentTime = 0
        context?.resume() // Make sure audio context is not suspended
        audio
          .play()
          .then(() => {
            if (duration) {
              setTimeout(() => {
                audio.pause()
                if (currentSourceRef.current && isConnectedRef.current) {
                  currentSourceRef.current.disconnect(node)
                  isConnectedRef.current = false
                }
                currentAudioRef.current = null
                callback?.()
              }, duration)
            } else {
              audio.onended = () => {
                if (currentSourceRef.current && isConnectedRef.current) {
                  currentSourceRef.current.disconnect(node)
                  isConnectedRef.current = false
                }
                currentAudioRef.current = null
              }
            }
          })
          .catch((e) => {
            reportError(e)
            // eslint-disable-next-line no-console
            console.error('Error playing audio file:', e)
          })
      }
    } catch (e) {
      reportError(e)
      // eslint-disable-next-line no-console
      console.error('Error playing audio file:', e)
    }
  }

  const playRing = (callback?: () => void) => {
    const audio = new Audio(ringing)
    play({ audio, duration: 2500, callback })
  }

  const playHangUp = () => {
    const audio = new Audio(hangUp)
    play({ audio })
  }

  const playThinking = () => {
    const audio = new Audio(thinking)
    play({ audio, volume: 0.1 })
  }

  return (
    <SoundsContext.Provider value={{ playRing, playHangUp, playThinking }}>
      {children}
    </SoundsContext.Provider>
  )
}
