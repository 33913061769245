import i18n from 'i18next'
import { isProductionEnv } from '@mc/client-env'
import { getCurrentLocale } from './getCurrentLocale'

export type I18nDocument =
  | string
  | { [x: string]: I18nDocument | I18nDocument[] }

export function getI18nInstance(): any {
  const currentLocale = getCurrentLocale()
  const newI18nInstance = i18n.createInstance()

  newI18nInstance.init(
    {
      lng: currentLocale,
      fallbackLng: 'en-US',
      debug: false,
      resources: {},
      ns: [],
      interpolation: {
        escapeValue: false,
        skipOnVariables: false,
      },
      saveMissing: true,
      // TODO: this appears to be incorrect usage
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      returnedObjectHandler: (_, value) => value.string,
      missingKeyHandler: (ng, ns, key, fallback) => {
        const errorMsg = `Missing Translation: ${key}, ${ng}, ${ns}, ${fallback}`

        if (isProductionEnv()) {
          // eslint-disable-next-line no-console
          console.error(errorMsg)
        } else {
          throw new Error(errorMsg)
        }
      },
      parseMissingKeyHandler: () => ' ',
      react: {
        bindI18n: 'languageChanged loaded',
        // TODO: this property isn't recognized in the types for i18next
        // maybe it's supposed to be bindI18nStore?
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        bindStore: 'added removed',
        nsMode: 'default',
        useSuspense: false,
      },
    },
    (error: unknown) => {
      if (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    },
  )

  return newI18nInstance
}

const globalInstance = getI18nInstance()

export function getGlobalI18nInstance(): any {
  return globalInstance
}
