import { getDataFromHeadTag } from '@mc/html-data-attr'

export type MembershipStatus =
  | 'active'
  | 'trialing'
  | 'lapsed'
  | 'SINGLE_CLASS'
  | 'inactive'
  | 'refunded'
  | 'NEVER_ENROLLED'

export interface Membership {
  id: string
  enterpriseBusinessUser?: boolean
  pricingHierarchy: number
  accessType?: string
  status: MembershipStatus
  providerType: string
  canceledOnPayPalDashboard: boolean
  originatorType: string
  remainingSubscriptionDays: number
  expirationDate: string
  cancelAtPeriodEnd?: boolean
}

export interface UserInfoProps {
  subscription?: Membership
}

const userInfoProps = getDataFromHeadTag('userInfo') as UserInfoProps

let membership: Partial<Membership> = {}

if (userInfoProps && userInfoProps.subscription) {
  membership = { ...userInfoProps.subscription }
}

export const getMembership = () => ({ ...membership })

export const forceUpdateMembership = (props: Partial<Membership>) => {
  membership = { ...props }
}
