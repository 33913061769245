import { useRouter } from 'next/router'

import { useAuth0User } from '../useAuth0User'
import { paths } from '../../utils/paths'

export const usePersonaPage = (pageNameFromNext?: string) => {
  const { query, pathname } = useRouter()
  const instructorSlug = query?.slug as string | undefined
  const { user } = useAuth0User()

  // Checkout first step signup page is handled by useAuthEvents
  if (pathname.includes(paths.personaCheckoutSignUp)) {
    return undefined
  }
  // Call page
  if (instructorSlug && pathname.includes('/call')) {
    return 'persona conversation'
  }
  // Checkout pages:
  if (pathname.includes(paths.personaCheckoutMembership)) {
    return 'step two login: plans (non-free trial)'
  }
  if (pathname.includes(paths.personaCheckoutPayment)) {
    return 'step three login: payment method (non-free trial)'
  }
  if (pathname.includes(paths.personaCheckoutComplete)) {
    return 'order completed'
  }

  // Bio page
  // Only case that we need to append (post-login) | (pre-login) is bio page, if it's not bio page, take pageName from pageSettings
  return instructorSlug
    ? `${instructorSlug} ${pageNameFromNext} ${user ? '(post-login)' : '(pre-login)'}`
    : pageNameFromNext
}
