import React, { createContext, useContext } from 'react'

type Country = string
type Region = string

type GeoContextType = {
  country: Country
  region?: Region
  isGeo: (geo: string) => boolean
  notGeo: (geo: string) => boolean
  oneOfGeo: (geo: string[]) => boolean
  isNorthAmerica: boolean
  hasProvinces: boolean
}

const GeoContext = createContext<GeoContextType | null>(null)

export const GeoProvider = ({
  value,
  children,
}: {
  value: Pick<GeoContextType, 'country' | 'region'>
  children: React.ReactNode
}) => (
  <GeoContext.Provider
    value={{
      ...value,
      isGeo: isGeo(value.country),
      notGeo: notGeo(value.country),
      oneOfGeo: oneOfGeo(value.country),
      isNorthAmerica: isNorthAmerica(value.country),
      hasProvinces: hasProvinces(value.country),
    }}
  >
    {children}
  </GeoContext.Provider>
)

export const useGeo = () => {
  const ctx = useContext(GeoContext)
  if (!ctx) throw new Error('GeoContext must be used within a GeoProvider')
  return ctx
}

type WithGeoProps = {
  geo: {
    country?: Country
    region?: Region
  }
}

export const withGeo = <PInner,>(
  Component: React.ComponentType<PInner & WithGeoProps>,
) =>
  function WithGeo(props: PInner) {
    const geo = useGeo()
    return <Component {...props} geo={geo} />
  }

/* utils */

const isGeo = (geo: string) => (comparedGeo: string) => geo === comparedGeo
const notGeo = (geo: string) => (comparedGeo: string) => geo !== comparedGeo
const oneOfGeo = (geo: string) => (comparedGeo: string[]) =>
  comparedGeo.includes(geo)
const isNorthAmerica = (geo: string) => oneOfGeo(geo)(['US', 'CA'])
const hasProvinces = (geo: string) => oneOfGeo(geo)(['CA', 'IN', 'AE'])
