export const TRACK_PROPS = {
  PRODUCT: 'product',
  USER_ID: 'user_id',
  PROFILE_ID: 'profile_id',
  SUBSCRIPTION_ID: 'subscription_id',

  VISIT_SESSION_ID: 'visit_session_id',
  PLAYER_SESSION_ID: 'player_session_id',
  MEDIA_SESSION_ID: 'media_session_id',

  CONTENT_TYPE: 'content_type',
  CONTENT_ID: 'content_id',
  CONTENT_UUID: 'content_uuid',
  CONTENT_TITLE: 'content_title',
  COURSE_ID: 'course_id',
  PARENT_UUID: 'parent_uuid',

  AUTOPLAY: 'auto_play',
  AUTO_QUALITY: 'auto_quality',
  POSITION: 'position',
  TOTAL_LENGTH: 'total_length',
  PLAYER: 'player',
  SOUND: 'sound',
  FULL_SCREEN: 'full_screen',
  PLAYBACK_LOCATION: 'playback_location',
  PICTURE_IN_PICTURE: 'picture_in_picture',
  QUALITY: 'quality',
  METHOD: 'method',
  SPEED: 'speed',
  SEEK_FROM_POSITION: 'seek_from_position',
  APP_BACKGROUNDED: 'app_backgrounded',
  CLOSED_CAPTION: 'cc',
  DEBUG: 'debug',
  AUDIO_TRACK: 'audio_track',

  PLAYER_NAME: 'player_name',
  PAGE_TYPE: 'page_type',
} as const
