import deepMapKeys from 'deep-map-keys'

import type { User } from '@mc/persona-api'

import { snakeToCamelCase } from 'libs/utils/string'
import { getHeaders } from 'pages/api/getHeaders'

export const getUser = async (accessToken: string) => {
  try {
    const response = await fetch(`${process.env.PERSONA_SERVER_URL}/users/me`, {
      method: 'GET',
      headers: {
        ...getHeaders(),
        authorization: `Bearer ${accessToken}`,
      },
    })
    if (!response.ok) {
      return null
    }
    const body = await response.json()
    const mapped = deepMapKeys(body, snakeToCamelCase)
    return mapped as User
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('error getting user', err)
    return null
  }
}
