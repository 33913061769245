type CacheEntry<TData> = {
  expires: number
  data: Promise<TData> // Store the promise in the cache to handle inflight requests
}

/**
 * Creates a memoized function with an internal cache.
 *
 * @param func The function to wrap.
 * @param keyResolver Builds a cache key. Receives the arguments passed to `func`, should return a string with the values used.
 * @param ttl Time to live. Cache entries are expunged upon cache miss.
 * @returns The cached value
 */
export const memoize = <
  TReturn,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (...args: any[]) => Promise<TReturn>,
  TParams extends Parameters<T>,
>(
  func: T,
  keyResolver: (...args: TParams) => string,
  ttl: number,
): T => {
  const cache = new Map<string, CacheEntry<TReturn>>()

  const cachedFn = async (...args: TParams) => {
    const key = keyResolver(...args)

    if (cache.has(key)) {
      const cached = cache.get(key)
      if (cached && cached.expires > Date.now()) {
        // Return the existing promise (handling inflight requests)
        return cached.data
      }
      cache.delete(key) // Expire old entries
    }

    // Start a new request and store the promise in the cache
    const dataPromise = func(...args)
    cache.set(key, { data: dataPromise, expires: Date.now() + ttl })

    try {
      const result = await dataPromise
      return result
    } catch (error) {
      // Ensure failed requests don't stay in the cache
      cache.delete(key)
      throw error
    }
  }

  return cachedFn as T
}
