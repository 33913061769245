/* eslint-disable import/first, global-require, react/prop-types */
require('libs/client/init-env').init() // eslint-disable-line

import './fonts.css'
import './base.scss'
import './highlight-shareable.scss'

import React from 'react'
import App, { AppContext, AppInitialProps } from 'next/app'
import Error from 'next/error'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Router, { NextRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'

import { I18nextProvider } from '@mc/i18n'
import { setGeo, setRegion } from '@mc/geo'
import { setFeatureFlips } from '@mc/feature-flip'
import { FeatureFlipsProvider } from '@mc/feature-flip-react'
import { setExperiments, setExperimentsVariables } from '@mc/experiments'
import { ExperimentsProvider } from '@mc/experiments-react'
import { GeoProvider } from '@mc/geo-react'
import { ErrorPage } from '@mc/error-page'
import { setServer } from '@mc/track-event'
import ErrorBoundary from '@mc/error-boundary'
import { reportError as reportBrowserError } from '@mc/monitoring'
import { OpenAPI } from '@mc/persona-api'
import {
  Auth0UserProvider,
  PersonaSoundsProvider,
  PersonaTrackProvider,
  i18nInstance,
  ReactQueryProvider,
  setLastPage,
  TierProvider,
  CheckoutTypes,
  AI_PERSONA_MVP_EXPERIMENT_FLAG,
} from '@mc/persona-client'
// eslint-disable-next-line no-restricted-imports
import { mediaStyle } from '@mc/media'

import { reportError } from 'libs/new-relic'
import { getUserAttributes } from 'libs/server/user-attributes'
import { getGrowthBookFlags } from 'libs/server/growthbook'
import middleware from 'middleware'
import { getUrlWithoutQueryParams } from 'libs/utils'
import Head from 'components/Head'
import { AnonymousId } from 'components/AnonymousId'
import { isStripeDisabledForSubscribersPartially } from 'experiments/persona_disable_stripe_for_subscribers_partially'

import { evaluateIfGetPersonaTiers, getPersonaTiers } from './utils/getTiers'

setServer('nextjs')

if (typeof window !== 'undefined') {
  OpenAPI.BASE = '/api/v1'
}

type PageProps = {
  userAttributes: ReturnType<typeof getUserAttributes>
  experiments: Awaited<
    ReturnType<typeof getGrowthBookFlags>
  >['growthBookExperiments']
  experimentsVariables: Awaited<
    ReturnType<typeof getGrowthBookFlags>
  >['growthBookExperimentsVariables']
  featureFlips: Awaited<
    ReturnType<typeof getGrowthBookFlags>
  >['growthBookFeatureFlips']
  error?: globalThis.Error & { statusCode: number }
  tiers: CheckoutTypes.Tier[]
}

type Props =
  | (AppInitialProps<PageProps> & {
      query?: ParsedUrlQuery
    })
  | {
      serverSideInitialPropsError: true
    }

type ComponentType = React.ComponentType<PageProps> & {
  pageSettings?: {
    title?: string
    pageName?: string
    analyticsLabel?: string
    pageLoadIntegrations?: Record<string, boolean>
  }
}

class Layout extends React.Component<
  Props & { Component: ComponentType; router: NextRouter }
> {
  constructor(props: Props & { Component: ComponentType; router: NextRouter }) {
    super(props)
    try {
      if (!('serverSideInitialPropsError' in props)) {
        const {
          userAttributes,
          experiments,
          experimentsVariables,
          featureFlips,
        } = props.pageProps

        // set lib data for react
        setGeo(userAttributes.country)
        setRegion(userAttributes.region)
        setFeatureFlips(featureFlips)
        setExperiments(experiments)
        setExperimentsVariables(experimentsVariables)
      }
    } catch (e) {
      reportError(e, {
        rootLayout: 'true',
        location: 'Layout constructor',
        message: 'Error occurred in _app.page.js Layout constructor',
      })
    }
  }

  static async getInitialProps(appContext: AppContext): Promise<Props> {
    const appProps: AppInitialProps<PageProps> =
      await App.getInitialProps(appContext)
    const { query, req, res } = appContext.ctx

    // runs on server only
    if (req) {
      try {
        middleware(req, res, appContext.router)

        const userAttributes = getUserAttributes(
          appContext.ctx,
          appContext.router,
        )
        const {
          growthBookExperiments,
          growthBookExperimentsVariables,
          growthBookFeatureFlips,
        } = await getGrowthBookFlags(userAttributes, req, appContext.router)
        // set lib data for getServerSideProps
        setGeo(userAttributes.country)
        setRegion(userAttributes.region)
        setFeatureFlips({ ...growthBookFeatureFlips })
        setExperiments({ ...growthBookExperiments })
        setExperimentsVariables({
          ...growthBookExperimentsVariables,
        })

        // Stripe disabled everywhere but the checkout flows
        const isStripeForNonSubscribersOnlyEnabled =
          isStripeDisabledForSubscribersPartially(growthBookExperiments)

        const isMVPEnabled =
          growthBookExperiments[AI_PERSONA_MVP_EXPERIMENT_FLAG]?.includes(
            'variant',
          ) || false

        const shouldFetchTiers = evaluateIfGetPersonaTiers({
          isPartial: isStripeForNonSubscribersOnlyEnabled,
          // TODO: add total stripe disable flag (even checkout)
          isTotal: false,
          path: appContext.ctx.pathname,
        })
        const {
          props: { tiers },
        } = shouldFetchTiers
          ? await getPersonaTiers(appContext.ctx, isMVPEnabled)
          : { props: { tiers: [] } }

        return {
          ...appProps,
          pageProps: {
            ...appProps.pageProps,
            experiments: {
              ...growthBookExperiments,
            },
            experimentsVariables: {
              ...growthBookExperimentsVariables,
            },
            featureFlips: {
              ...growthBookFeatureFlips,
            },
            userAttributes,
            tiers,
          },
          query,
        }
      } catch (e) {
        reportError(e, {
          rootLayout: 'true',
          location: 'getInitialProps',
          message: 'Error occurred in _app.page.js getInitialProps',
        })
        return {
          serverSideInitialPropsError: true,
        }
      }
    }

    return { ...appProps }
  }

  handleRouteChange = () => {
    if (typeof window !== 'undefined') {
      setLastPage()
    }
  }

  componentDidMount() {
    // Store the initial page
    if (typeof window !== 'undefined') {
      setLastPage()
    }

    // Listen for route changes and update last page
    Router.events.on('routeChangeStart', this.handleRouteChange)
  }

  componentWillUnmount() {
    Router.events.off('routeChangeStart', this.handleRouteChange)
  }

  render() {
    if ('serverSideInitialPropsError' in this.props) {
      return <ErrorPage />
    }

    const { Component, pageProps, router } = this.props
    const pageTitle = Component.pageSettings?.title || 'MasterClass On Call'
    const { userAttributes } = pageProps
    const country = userAttributes?.country
    const region = userAttributes?.region
    const experimentsProviderValues = {
      experiments: pageProps?.experiments,
      experimentsVariables: pageProps?.experimentsVariables,
    }
    const featureFlips = pageProps?.featureFlips

    const mcPageClassName = 'mc-page'

    return (
      <>
        <ErrorBoundary
          fallback={() => <ErrorPage />}
          onError={(e) =>
            reportBrowserError(new globalThis.Error('Layout ErrorBoundary'), {
              error: String(e),
              stack: e?.stack as string,
            })
          }
        >
          <AnonymousId ajsAnonId={userAttributes?.userId} />
          <I18nextProvider i18n={i18nInstance} defaultNS='@mc/persona'>
            <FeatureFlipsProvider value={featureFlips}>
              <ExperimentsProvider value={experimentsProviderValues}>
                <ReactQueryProvider>
                  <GeoProvider value={{ country, region }}>
                    <Auth0UserProvider>
                      <TierProvider tiers={pageProps.tiers}>
                        <PersonaTrackProvider
                          pageName={Component.pageSettings?.pageName}
                        >
                          <Head
                            analyticsLabel={
                              Component.pageSettings?.analyticsLabel
                            }
                            analyticsIntegrations={
                              Component.pageSettings?.pageLoadIntegrations
                            }
                          >
                            <title>{pageTitle}</title>
                            <style type='text/css'>{mediaStyle}</style>
                            <link rel='icon' href='/_next-public/favicon.ico' />
                            <link
                              rel='canonical'
                              href={getUrlWithoutQueryParams(router.asPath)}
                            />
                            <link
                              as='font'
                              crossOrigin=''
                              href='/_next-public/fonts/sohne-schmal-dreiviertelfett.woff2'
                              rel='preload'
                              type='font/woff2'
                            />
                          </Head>
                          <div className={mcPageClassName}>
                            <main className='mc-page__content' id='mc-main'>
                              {!pageProps.error ? (
                                <PersonaSoundsProvider>
                                  <Component {...pageProps} />
                                </PersonaSoundsProvider>
                              ) : (
                                <Error
                                  statusCode={pageProps.error.statusCode}
                                  title={pageProps.error.message}
                                />
                              )}
                            </main>
                          </div>
                        </PersonaTrackProvider>
                      </TierProvider>
                    </Auth0UserProvider>
                  </GeoProvider>
                  <ReactQueryDevtools initialIsOpen={false} />
                </ReactQueryProvider>
              </ExperimentsProvider>
            </FeatureFlipsProvider>
          </I18nextProvider>
        </ErrorBoundary>
      </>
    )
  }
}

export default Layout
