import { getAccountPropertiesAsync } from './getAccountPropertiesAsync'

export const getAccountIdAsync = async (refresh = false) => {
  try {
    const { id } = await getAccountPropertiesAsync(refresh)
    return id
  } catch (err) {
    return null
  }
}
