import { pickBy } from 'lodash'
import { ParsedUrlQuery } from 'querystring'

import { getEnvVar } from '@mc/client-env'

export const logSegmentCalls = (
  type: string,
  event: string,
  inputProperties?: object,
  options?: object,
) => {
  const logEnv = getEnvVar('LOG_SEGMENT_CALLS')
  if (logEnv === true || logEnv === 'true') {
    let output = `analytics.${type}('${event}'`
    if (inputProperties) {
      output += `, ${JSON.stringify(inputProperties, null, 2)}`
    }
    if (options && Object.keys(options).length) {
      output += `, ${JSON.stringify(options, null, 2)}`
    }
    output += ')'

    console.groupCollapsed(`Segment event: ${event}`) // eslint-disable-line
    console.info(`%c ${output}`, 'color: #59a2ea') // eslint-disable-line
    console.groupEnd() // eslint-disable-line
  }
}

const ALLOWED_CAMPAIGN_PARAMS = [
  // UTM parameters
  'utm_source', // Campaign attribution source (e.g., facebook)
  'utm_medium', // The advertising medium (e.g., social, cpc)
  'utm_campaign', // The specific campaign name or ID
  'utm_content', // Content or creative used for A/B testing
  'utm_term', // Keyword targeting information (optional)
  // FB parameters
  'fbclid', // Facebook Click Identifier
  'campaign_id', // The ID of the campaign
  'adset_id', // The ID of the ad set
  'ad_id', // The ID of the individual ad
  'placement', // Placement of the ad (e.g., facebook_feed, instagram_story)
  'creative_id', // The ID of the ad creative used in the campaign
  'targeting_id', // The ID of the audience or targeting group
  'site_source_name', // Specifies where the ad was shown (e.g., Facebook, Instagram)
  'ref', // Referrer, specifying the original source
  'source', // The originating traffic source (e.g., Facebook, Instagram)
]

export const getCampaignParams = (queryParams?: ParsedUrlQuery) => {
  if (queryParams) {
    const campaignVariables = pickBy(queryParams, (_, key) =>
      ALLOWED_CAMPAIGN_PARAMS.includes(key),
    )
    const hasCampaignVariables = Object.keys(campaignVariables).length > 0
    if (hasCampaignVariables) {
      // remove utm_ prefix
      Object.keys(campaignVariables).forEach((key) => {
        // campaign case -> name
        if (key === 'utm_campaign') {
          campaignVariables.name = campaignVariables[key]
          delete campaignVariables[key]
        }
        // Rest of the utm_ parameters
        if (key.startsWith('utm_')) {
          campaignVariables[key.replace('utm_', '')] = campaignVariables[key]
          delete campaignVariables[key]
        }
      })
      return {
        campaign: campaignVariables,
      }
    }
    return {}
  }
  return {}
}
