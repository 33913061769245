import React, { createContext, useContext } from 'react'

export type FeatureFlips = Record<string, boolean>

const FeatureFlipsContext = createContext<FeatureFlips | null>(null)

type FeatureFlipProviderProps = {
  value: FeatureFlips
  children: React.ReactNode
}

export const FeatureFlipsProvider = ({
  value,
  children,
}: FeatureFlipProviderProps) => (
  <FeatureFlipsContext.Provider value={value}>
    {children}
  </FeatureFlipsContext.Provider>
)

export const useFeatureFlips = () => {
  const ctx = useContext(FeatureFlipsContext)
  if (!ctx) {
    throw new Error('useFeatureFlip requires FeatureFlipsContext.Provider')
  }
  return ctx
}

// TODO: useFeatureFlip
