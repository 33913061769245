import { getDataFromHeadTag } from '@mc/html-data-attr'

let geo = getDataFromHeadTag('geo') as string

export const setGeo = (nextGeo: string) => {
  geo = nextGeo
}

export default function geoRepository() {
  // if (typeof window === 'undefined') {
  //   throw new Error('global `geo` repository can only be used in the browser')
  // }
  return geo
}
