import { pickBy, difference } from 'lodash'

import { reportError } from '@mc/monitoring'
import {
  isLoggedIn,
  isLoggedInAsync,
  getAccountIdAsync,
  getAccountProperties,
  getCurrentProfile,
  getCurrentProfileId,
  hasActiveAnnualMembership,
  getMembershipId,
  getPartnershipPropertiesAsync,
  isPartnershipUserAsync,
  hasActiveAnnualMembershipAsync,
  getAccountPropertiesAsync,
  getMembershipPropertiesAsync,
} from '@mc/user-info'
import { getExperiments, getBucket } from '@mc/experiments'
import { getGeo, getRegion } from '@mc/geo'
import { getCurrentLocale, getBrowserLocales } from '@mc/i18n'
import { experiment as rumExperiment, metrics as rumMetrics } from '@mc/rum'

import createOrGetVisitSessionIdFromCookie from '../../interactors/createOrGetVisitSessionIdFromCookie'
import getFacebookBrowserId from '../../interactors/getFacebookBrowserId'
import getFacebookClickId from '../../interactors/getFacebookClickId'
import createEventId from '../../interactors/createEventId'
import { TRACK_PROPS } from '../../../media/interactors/trackMediaConstants'
import { trackULExperimentVariantExposure } from '../userLeap'
import {
  EventTypes,
  Locations,
  Actions,
  Type,
  Pages,
  PageViewEvents,
  PageLoadEvents,
  EnterpriseCartEvents,
  NavigationEvents,
  PlaylistEvents,
  StudentHomeEvents,
  ContentRowEvents,
  NavigationModalEvents,
  ContentTypes,
  VideoPlayerPageEvents,
  AD_PARTNERS,
} from './constants'
import { logSegmentCalls } from './utils'

type Server = 'nextjs' | 'rails' | 'unknown'

let server: Server = 'unknown'

export const setServer = (newServer: Server) => {
  server = newServer
}

const addProfileId = () =>
  isLoggedIn()
    ? {
        profile_id:
          getCurrentProfileId() ??
          getCurrentProfile()?.id ??
          getAccountProperties().profileId,
      }
    : {}

const addFacebookIds = () =>
  hasActiveAnnualMembership()
    ? {}
    : {
        fbp: getFacebookBrowserId(),
        fbc: getFacebookClickId(),
      }

// properties passed to all track events
const getEventProps = (properties: object): Record<string, unknown> => ({
  subscription_id: getMembershipId(),
  active_annual_pass: hasActiveAnnualMembership(),
  user_locale: getCurrentLocale(),
  native_locale: getBrowserLocales(),
  geo: getGeo(),
  region: getRegion(),
  event_id: createEventId(),
  platform: 'web',
  [TRACK_PROPS.VISIT_SESSION_ID]: createOrGetVisitSessionIdFromCookie(),
  ...addProfileId(),
  ...addFacebookIds(),
  ...properties,
  ...getPostPaintedDoor(),
})

const getPostPaintedDoor = () => {
  // prcng-107_v1
  if (typeof window === 'undefined') return undefined

  const value = window?.localStorage?.getItem('post_painted_door_stage')

  return value !== null ? { post_painted_door: value } : undefined
}

const getUserExperiments = () => {
  const experiments = pickBy(getExperiments())
  return Object.entries(experiments).map(([name, value]) => `${name}_${value}`)
}

export const identify = (
  userId?: number | null,
  traits = {},
  options: SegmentAnalytics.SegmentOpts = {},
) => {
  const userTraits = {
    ...traits,
    experiments: getUserExperiments(),
    user_locale: getCurrentLocale(),
    native_locale: getBrowserLocales(),
    geo: getGeo(),
    region: getRegion(),
    event_id: createEventId(),
    [TRACK_PROPS.VISIT_SESSION_ID]: createOrGetVisitSessionIdFromCookie(),
    ...addProfileId(),
    ...addFacebookIds(),
  }
  if (window.analytics) {
    const integrations = {
      Pardot: false,
      ...options.integrations,
    }
    // identify -- Pardot is off by default
    window.analytics.identify(
      // for backwards compatibility - we need to continue sending `null`
      (userId?.toString() ?? null) as string,
      userTraits,
      {
        ...options,
        integrations,
        vendor_integrations: integrations,
      } as SegmentAnalytics.SegmentOpts,
      () => {
        logSegmentCalls('identify', 'identify', userTraits)
      },
    )
  }
}

export const resetSegmentIdentity = () => {
  if (window.analytics) {
    window.analytics.reset()
  }
}

export const page = async (
  event: string,
  properties = {},
  options = {
    integrations: {},
  },
) => {
  const userTraits = {
    ...properties,
    user_locale: getCurrentLocale(),
    native_locale: getBrowserLocales(),
    geo: getGeo(),
    region: getRegion(),
    event_id: createEventId(),
    [TRACK_PROPS.VISIT_SESSION_ID]: createOrGetVisitSessionIdFromCookie(),
    ...addProfileId(),
    ...addFacebookIds(),
  }
  const isMember = await hasActiveAnnualMembershipAsync()
  // we don't want to send page views to Ad Partners on course (and other) pages due to legal reasons
  const integrations = {
    ...adPartnersTo(!isMember),
    ...options.integrations,
  }
  const mergedOptions = {
    ...options,
    served_by: server,
    integrations,
    vendor_integrations: integrations,
  }

  if (window.analytics) {
    window.analytics.page(event, userTraits, mergedOptions, () => {
      logSegmentCalls('page', event, userTraits, mergedOptions)
    })
  }
}

export const trackPage = async (
  event = '',
  properties = {},
  options = {
    integrations: {},
  },
) => {
  try {
    if (await isLoggedInAsync()) {
      const { email, emailToken, enrolledCourses, activeAnnualPass } =
        await getAccountPropertiesAsync()
      // TODO: When enterprise experience is ready, use isEnterpriseExperience to replace the value
      const experience = 'consumer'

      const { id } = await getMembershipPropertiesAsync(false)

      const userTraits = {
        email,
        email_token: emailToken,
        enrolled_courses: enrolledCourses,
        annual_pass: activeAnnualPass,
        subscription_id: id,
      }

      let partnershipTraits = {}

      if (await isPartnershipUserAsync()) {
        const partnershipData = await getPartnershipPropertiesAsync()
        if (partnershipData.partnerName) {
          const {
            partnerName,
            partnerDisplayName,
            partnerPaymentGateway,
            resellerSkuDisplayName,
            sku,
            skuSlug,
          } = partnershipData

          partnershipTraits = {
            partnership: {
              partner_payment_gateway: partnerPaymentGateway,
              partner_name: partnerName,
              partner_display_name: partnerDisplayName,
              partner_sku_display_name: resellerSkuDisplayName,
              partner_sku: sku,
              partner_sku_slug: skuSlug,
            },
          }
        }
      }

      identify(await getAccountIdAsync(), {
        experience,
        ...userTraits,
        ...partnershipTraits,
      })
    } else {
      // sends experiment data for logged out users
      identify(null)
    }

    if (event !== '') {
      let mergedProperties: Record<string, unknown> = {
        ...properties,
        ...getPostPaintedDoor(),
      }

      // Appending search, url, and path directly to all trackPage calls for client-side page load calls when analyticsLabel is not used in a page to avoid sending on every call
      if (document && window) {
        const { search, pathname: path, href: url } = window.location
        const { referrer, title } = document
        mergedProperties = {
          // if referred is manually sent it takes precedence over the referrer here (document.referrer is only set once and doesn't change for client-side navigation)
          ...(referrer && { referrer }),
          ...mergedProperties,
          title,
          url,
          path,
          search,
        }
      }

      page(event, mergedProperties, options)
    }

    if (typeof window !== 'undefined' && window) {
      rumMetrics(event, (metric) =>
        track('Core Web Vital', {
          ...metric,
          path: window?.location?.pathname,
        }),
      )
    }
  } catch (e) {
    reportError(e as Error)
  }

  return Promise.resolve()
}

const checkExperimentViewedCalled = ({
  experimentName,
}: {
  experimentName?: string
  [k: string]: string | number | boolean | object | null | undefined
}) => {
  const EXPERIMENT_KEY = 'experiments'
  let calledExperiments = []
  const experimentCookie = window.sessionStorage.getItem(EXPERIMENT_KEY)
  if (experimentCookie) {
    calledExperiments = JSON.parse(experimentCookie)
  }
  const activeExperiments = Object.keys(getExperiments())
  if (!calledExperiments.includes(experimentName)) {
    calledExperiments.push(experimentName)
    const inactiveExperiments = difference(calledExperiments, activeExperiments)
    calledExperiments = difference(calledExperiments, inactiveExperiments)
    window.sessionStorage.setItem(
      EXPERIMENT_KEY,
      JSON.stringify(calledExperiments),
    )
    return false
  }
  return true
}

export const adPartnersTo = (value = false) =>
  AD_PARTNERS.reduce(
    (partners, partner) => ({ ...partners, [partner]: value }),
    { All: true },
  )

export const track = (
  event: string,
  properties: Record<
    string,
    string | number | boolean | object | null | undefined
  > = {},
  options: {
    integrations?: Record<string, boolean>
  } = {
    integrations: adPartnersTo(false),
  },
) => {
  if (
    // eslint-disable-next-line
    process.env.NODE_ENV === 'production' &&
    event === EventTypes.EXPERIMENT_VIEWED &&
    checkExperimentViewedCalled(properties)
  ) {
    return
  }

  const mergedProps = getEventProps(properties)
  const mergedOptions = {
    ...options,
    served_by: server,
    // MTECH-198: copying the integrations in the context so it flows into the data warehouse
    // by default, the integrations property is blocked by the data warehouse
    vendor_integrations: {
      ...options.integrations,
    },
  }

  if (typeof window !== 'undefined' && window.analytics) {
    window.analytics.track(event, mergedProps, mergedOptions, () => {
      logSegmentCalls('track', event, mergedProps, mergedOptions)
    })
  }
}

export const trackKeyGrowthEvent = async (
  event: string,
  properties = {},
  options = {
    integrations: adPartnersTo(true),
  },
) => {
  // Gold Standard Tracking Events:
  // https://masterclass-dev.atlassian.net/wiki/spaces/CROS/pages/928940121/
  //  Conversion+Funnel+Event+Tracking+Cleanup+Initiative#Review-the-Current-State-of-Data-Tracking
  // Don't want to send tracking events to ad partners for post-paywall users for legal reasons
  if (await hasActiveAnnualMembershipAsync()) {
    track(event, properties, {
      integrations: adPartnersTo(false),
    })
  } else {
    track(event, properties, options)
  }
}

export const trackExperimentViewed = (
  experimentName: string,
  properties = {},
  options: { trackUserLeap?: boolean } = {},
) => {
  if (getBucket(experimentName)) {
    const variationName = getBucket(experimentName)
    track(EventTypes.EXPERIMENT_VIEWED, {
      experimentName,
      variationName,
      ...properties,
    })

    if (options.trackUserLeap && variationName !== 'control') {
      trackULExperimentVariantExposure(experimentName)
    }

    rumExperiment(experimentName, variationName)
  }
}

export const trackExperimentBucketed = (
  experimentName: string,
  properties = {},
) => {
  const variationName = getBucket(experimentName)

  if (variationName) {
    track(EventTypes.EXPERIMENT_BUCKETED, {
      experimentName,
      variationName,
      ...properties,
    })
  }
}

export default {
  EventTypes,
  Locations,
  Actions,
  Type,
  Pages,
  PageViewEvents,
  PageLoadEvents,
  trackPage,
  identify,
  page,
  track,
  trackKeyGrowthEvent,
  EnterpriseCartEvents,
  NavigationEvents,
  PlaylistEvents,
  StudentHomeEvents,
  ContentRowEvents,
  NavigationModalEvents,
  ContentTypes,
  VideoPlayerPageEvents,
  resetSegmentIdentity,
}
