import { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { trackExperimentViewed } from '@mc/track-event'

export default class ExperimentTracker extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    bucket: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
  }

  componentDidMount() {
    trackExperimentViewed(this.props.name)
  }

  render() {
    return this.props.children
  }
}
