import { GetServerSidePropsContext, NextApiResponse } from 'next'
import Cookies from 'cookie-universal'

import {
  AI_PERSONA_ID_SESSION_COOKIE_NAME,
  AI_PERSONA_REFRESH_TOKEN_COOKIE_NAME,
  AI_PERSONA_SESSION_COOKIE_NAME,
} from '@mc/persona-client'

export const setAuthHeaders = ({
  res,
  accessToken,
  idToken,
  expiresIn,
  refreshToken,
}: {
  res: NextApiResponse | GetServerSidePropsContext['res']
  accessToken: string
  idToken?: string
  expiresIn: number
  refreshToken?: string
}) => {
  const cookies = Cookies()
  const expires = new Date(Date.now() + expiresIn * 1000) // expiresIn in seconds
  const isSecure = process.env.NODE_ENV === 'production'
  const properties = {
    expires,
    secure: isSecure,
    httpOnly: true,
    path: '/',
    sameSite: 'lax',
  } as const

  let refreshTokenCookie
  if (refreshToken) {
    refreshTokenCookie = cookies.nodeCookie.serialize(
      AI_PERSONA_REFRESH_TOKEN_COOKIE_NAME,
      refreshToken,
      properties,
    )
  }

  const sessionCookie = cookies.nodeCookie.serialize(
    AI_PERSONA_SESSION_COOKIE_NAME,
    accessToken,
    properties,
  )

  let idTokenCookie = ''
  if (idToken) {
    idTokenCookie = cookies.nodeCookie.serialize(
      AI_PERSONA_ID_SESSION_COOKIE_NAME,
      idToken,
      properties,
    )
  }

  res.setHeader('Access-Control-Allow-Credentials', 'true')
  res.setHeader('Set-Cookie', [
    sessionCookie,
    ...(idTokenCookie ? [idTokenCookie] : []),
    ...(refreshTokenCookie ? [refreshTokenCookie] : []),
  ])
}
