export const CURRENT_PROFILE_ID_KEY = 'currentProfileId'

export const getCurrentProfileId = (): number | null => {
  const idString = localStorage.getItem(CURRENT_PROFILE_ID_KEY)

  if (idString === null) {
    return idString
  }

  return Number.parseInt(idString, 10)
}
