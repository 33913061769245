export const COUNTRY_TO_CURRENCY_MAP: { [key: string]: string } = {
  AL: 'USD',
  AM: 'USD',
  AU: 'AUD',
  AT: 'EUR',
  BS: 'USD',
  BH: 'USD',
  BB: 'USD',
  BY: 'USD',
  BE: 'EUR',
  BJ: 'USD',
  BA: 'EUR',
  BR: 'BRL',
  BG: 'BGN',
  KH: 'USD',
  CM: 'USD',
  CA: 'CAD',
  CL: 'CLP',
  CN: 'CNY',
  CO: 'COP',
  CI: 'USD',
  HR: 'EUR',
  CY: 'EUR',
  CZ: 'CZK',
  DK: 'DKK',
  EG: 'EGP',
  EE: 'EUR',
  FI: 'EUR',
  FR: 'EUR',
  GE: 'USD',
  DE: 'EUR',
  GH: 'USD',
  GR: 'EUR',
  HK: 'HKD',
  HU: 'HUF',
  IS: 'USD',
  IN: 'INR',
  ID: 'IDR',
  IE: 'EUR',
  IL: 'ILS',
  IT: 'EUR',
  JP: 'JPY',
  KZ: 'KZT',
  KE: 'USD',
  KR: 'KRW',
  XK: 'EUR',
  KG: 'USD',
  LV: 'EUR',
  LT: 'EUR',
  LU: 'EUR',
  MY: 'MYR',
  MT: 'EUR',
  MX: 'MXN',
  MD: 'USD',
  ME: 'EUR',
  NP: 'USD',
  NL: 'EUR',
  NZ: 'NZD',
  NG: 'NGN',
  NO: 'NOK',
  PK: 'PKR',
  PE: 'PEN',
  PH: 'PHP',
  PL: 'PLN',
  PT: 'EUR',
  QA: 'QAR',
  RO: 'RON',
  RU: 'RUB',
  SA: 'SAR',
  RS: 'EUR',
  SG: 'SGD',
  SK: 'EUR',
  SI: 'EUR',
  ZA: 'ZAR',
  ES: 'EUR',
  SR: 'USD',
  SE: 'SEK',
  CH: 'CHF',
  TW: 'TWD',
  TJ: 'USD',
  TZ: 'TZS',
  TH: 'THB',
  TR: 'TRY',
  UG: 'USD',
  UA: 'USD',
  AE: 'AED',
  GB: 'GBP',
  UZ: 'USD',
  ZM: 'USD',
  ZW: 'USD',
}

export const COUNTRY_TO_CURRENCY_SYMBOL_MAP: { [key: string]: string } = {
  [COUNTRY_TO_CURRENCY_MAP.AL]: '$',
  [COUNTRY_TO_CURRENCY_MAP.AM]: '$',
  [COUNTRY_TO_CURRENCY_MAP.AU]: 'A$',
  [COUNTRY_TO_CURRENCY_MAP.AT]: '€',
  [COUNTRY_TO_CURRENCY_MAP.BS]: '$',
  [COUNTRY_TO_CURRENCY_MAP.BH]: '$',
  [COUNTRY_TO_CURRENCY_MAP.BB]: '$',
  [COUNTRY_TO_CURRENCY_MAP.BY]: '$',
  [COUNTRY_TO_CURRENCY_MAP.BE]: '€',
  [COUNTRY_TO_CURRENCY_MAP.BJ]: '$',
  [COUNTRY_TO_CURRENCY_MAP.BA]: '€',
  [COUNTRY_TO_CURRENCY_MAP.BR]: 'R$',
  [COUNTRY_TO_CURRENCY_MAP.BG]: 'лв',
  [COUNTRY_TO_CURRENCY_MAP.KH]: '$',
  [COUNTRY_TO_CURRENCY_MAP.CM]: '$',
  [COUNTRY_TO_CURRENCY_MAP.CA]: 'C$',
  [COUNTRY_TO_CURRENCY_MAP.CL]: 'CLP$',
  [COUNTRY_TO_CURRENCY_MAP.CN]: '¥',
  [COUNTRY_TO_CURRENCY_MAP.CO]: 'COP$',
  [COUNTRY_TO_CURRENCY_MAP.CI]: '$',
  [COUNTRY_TO_CURRENCY_MAP.HR]: '€',
  [COUNTRY_TO_CURRENCY_MAP.CY]: '€',
  [COUNTRY_TO_CURRENCY_MAP.CZ]: 'Kč',
  [COUNTRY_TO_CURRENCY_MAP.DK]: 'kr',
  [COUNTRY_TO_CURRENCY_MAP.EG]: 'E£',
  [COUNTRY_TO_CURRENCY_MAP.EE]: '€',
  [COUNTRY_TO_CURRENCY_MAP.FI]: '€',
  [COUNTRY_TO_CURRENCY_MAP.FR]: '€',
  [COUNTRY_TO_CURRENCY_MAP.GE]: '$',
  [COUNTRY_TO_CURRENCY_MAP.DE]: '€',
  [COUNTRY_TO_CURRENCY_MAP.GH]: '$',
  [COUNTRY_TO_CURRENCY_MAP.GR]: '€',
  [COUNTRY_TO_CURRENCY_MAP.HK]: 'HK$',
  [COUNTRY_TO_CURRENCY_MAP.HU]: 'Ft',
  [COUNTRY_TO_CURRENCY_MAP.IS]: '$',
  [COUNTRY_TO_CURRENCY_MAP.IN]: '₹',
  [COUNTRY_TO_CURRENCY_MAP.ID]: 'Rp',
  [COUNTRY_TO_CURRENCY_MAP.IE]: '€',
  [COUNTRY_TO_CURRENCY_MAP.IL]: '₪',
  [COUNTRY_TO_CURRENCY_MAP.IT]: '€',
  [COUNTRY_TO_CURRENCY_MAP.JP]: '¥',
  [COUNTRY_TO_CURRENCY_MAP.KZ]: '₸',
  [COUNTRY_TO_CURRENCY_MAP.KE]: '$',
  [COUNTRY_TO_CURRENCY_MAP.KR]: '₩',
  [COUNTRY_TO_CURRENCY_MAP.XK]: '€',
  [COUNTRY_TO_CURRENCY_MAP.KG]: '$',
  [COUNTRY_TO_CURRENCY_MAP.LV]: '€',
  [COUNTRY_TO_CURRENCY_MAP.LT]: '€',
  [COUNTRY_TO_CURRENCY_MAP.LU]: '€',
  [COUNTRY_TO_CURRENCY_MAP.MY]: 'RM',
  [COUNTRY_TO_CURRENCY_MAP.MT]: '€',
  [COUNTRY_TO_CURRENCY_MAP.MX]: 'MX$',
  [COUNTRY_TO_CURRENCY_MAP.MD]: '$',
  [COUNTRY_TO_CURRENCY_MAP.ME]: '€',
  [COUNTRY_TO_CURRENCY_MAP.NP]: '$',
  [COUNTRY_TO_CURRENCY_MAP.NL]: '€',
  [COUNTRY_TO_CURRENCY_MAP.NZ]: '$',
  [COUNTRY_TO_CURRENCY_MAP.NG]: '₦',
  [COUNTRY_TO_CURRENCY_MAP.NO]: 'kr',
  [COUNTRY_TO_CURRENCY_MAP.PK]: '₨',
  [COUNTRY_TO_CURRENCY_MAP.PE]: 'S/',
  [COUNTRY_TO_CURRENCY_MAP.PH]: '₱',
  [COUNTRY_TO_CURRENCY_MAP.PL]: 'zł',
  [COUNTRY_TO_CURRENCY_MAP.PT]: '€',
  [COUNTRY_TO_CURRENCY_MAP.QA]: 'ر.ق',
  [COUNTRY_TO_CURRENCY_MAP.RO]: 'lei',
  [COUNTRY_TO_CURRENCY_MAP.RU]: '₽',
  [COUNTRY_TO_CURRENCY_MAP.SA]: 'ر.س',
  [COUNTRY_TO_CURRENCY_MAP.RS]: '€',
  [COUNTRY_TO_CURRENCY_MAP.SG]: 'S$',
  [COUNTRY_TO_CURRENCY_MAP.SK]: '€',
  [COUNTRY_TO_CURRENCY_MAP.SI]: '€',
  [COUNTRY_TO_CURRENCY_MAP.ZA]: 'R',
  [COUNTRY_TO_CURRENCY_MAP.ES]: '€',
  [COUNTRY_TO_CURRENCY_MAP.SR]: '$',
  [COUNTRY_TO_CURRENCY_MAP.SE]: 'kr',
  [COUNTRY_TO_CURRENCY_MAP.CH]: 'Fr',
  [COUNTRY_TO_CURRENCY_MAP.TW]: 'NT$',
  [COUNTRY_TO_CURRENCY_MAP.TJ]: '$',
  [COUNTRY_TO_CURRENCY_MAP.TZ]: 'TSh',
  [COUNTRY_TO_CURRENCY_MAP.TH]: '฿',
  [COUNTRY_TO_CURRENCY_MAP.TR]: '₺',
  [COUNTRY_TO_CURRENCY_MAP.UG]: '$',
  [COUNTRY_TO_CURRENCY_MAP.UA]: '$',
  [COUNTRY_TO_CURRENCY_MAP.AE]: 'د.إ',
  [COUNTRY_TO_CURRENCY_MAP.GB]: '£',
  [COUNTRY_TO_CURRENCY_MAP.UZ]: '$',
  [COUNTRY_TO_CURRENCY_MAP.ZM]: '$',
  [COUNTRY_TO_CURRENCY_MAP.ZW]: '$',
}
