import { UserProvider, useUser } from '@auth0/nextjs-auth0/client'
import { SubscriptionsService, User, UsersService } from '@mc/persona-api'
import { useQuery } from '@tanstack/react-query'
import React, { createContext, useMemo } from 'react'

import { useIsAiPersonaMVPEnabled } from '../../experiments/dais-1128_v1'
import { Auth0Service } from '../../services/Auth0Service'
import { paths } from '../../utils/paths'

type Subscription = {
  resourceIdentifier?: string
  gateway?: string
  productDisplayName?: string
  renewsAt?: string
  expiresAt?: string
  renewalCanceled?: boolean
}

type TContext = {
  user: { email: string; name: string; picture?: string } | undefined
  me: User | undefined
  isLoading: boolean
  subscription?: Subscription
  isLoadingMe: boolean
  isLoadingSubscription: boolean
  refetchSubscription: () => void
}

export const Auth0UserContext = createContext<TContext>({
  user: undefined,
  isLoading: false,
  me: undefined,
  isLoadingMe: false,
  isLoadingSubscription: false,
  refetchSubscription: () => {},
})

const WrapperMVP = ({ children }: { children: React.ReactNode }) => {
  const isMVPEnabled = useIsAiPersonaMVPEnabled()

  // Needed API call to change from reservation entitlement to entitlement
  const { data: userMCMe, isLoading: isLoadingMe } = useQuery({
    queryFn: () => UsersService.getUser({ uuid: 'me' }),
    queryKey: ['me'],
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const { user: userBeta, isLoading: isLoadingBeta } = useUser()
  const { data: userMVP, isLoading: isLoadingMVP } = useQuery({
    enabled: isMVPEnabled,
    queryFn: Auth0Service.userInfo,
    queryKey: ['userInfo'],
    retry: false,
    refetchOnWindowFocus: false,
  })

  const {
    data: subscription,
    failureCount,
    isFetched,
    refetch: refetchSubscription,
  } = useQuery({
    queryFn: () => SubscriptionsService.getSubscription({ id: 'me' }),
    queryKey: ['subscription'],
    retry: 3,
    enabled: !!userMCMe,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
  const subscriptionLoaded = useMemo(
    () => failureCount > 0 || isFetched,
    [failureCount, isFetched],
  )

  const result = useMemo(
    () =>
      isMVPEnabled
        ? { user: userMVP, isLoading: isLoadingMVP }
        : { user: userBeta, isLoading: isLoadingBeta },
    [isMVPEnabled, userMVP, isLoadingMVP, userBeta, isLoadingBeta],
  )

  return (
    <Auth0UserContext.Provider
      value={{
        ...result,
        me: userMCMe,
        subscription,
        isLoadingMe,
        isLoadingSubscription: !subscriptionLoaded,
        refetchSubscription,
      }}
    >
      {children}
    </Auth0UserContext.Provider>
  )
}

export const Auth0UserProvider = ({
  children,
}: {
  children: React.ReactNode
}) => (
  <UserProvider profileUrl={paths.personaAuth('me')}>
    <WrapperMVP>{children}</WrapperMVP>
  </UserProvider>
)
