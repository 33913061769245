import { getDataFromHeadTag } from '@mc/html-data-attr'

export type FeatureFlips = Record<string, boolean>

let featureFlips = getDataFromHeadTag('featureFlips') as FeatureFlips

export const setFeatureFlips = (props: FeatureFlips = {}) => {
  featureFlips = { ...props }
}

export const getFeatureFlips = () => ({ ...featureFlips })
