import { createMedia } from '@artsy/fresnel'
import { MediaContextProviderProps } from '@artsy/fresnel/dist/Media'

import { BREAKPOINTS } from './constants'

const {
  Media,
  MediaContextProvider: _MediaContextProvider,
  createMediaStyle,
} = createMedia({
  breakpoints: {
    xs: BREAKPOINTS.XS,
    sm: BREAKPOINTS.SM,
    md: BREAKPOINTS.MD,
    lg: BREAKPOINTS.LG,
    xl: BREAKPOINTS.XL,
  },
})

const mediaStyle = createMediaStyle()

// TODO: Adding a children prop to get around type mismatch between
// React 18 and @artsy/fresnel's dependency on React 17
// Remove after upgrading @artsy/fresnel
const MediaContextProvider = _MediaContextProvider as React.ComponentType<
  MediaContextProviderProps<'xs' | 'sm' | 'md' | 'lg' | 'xl'> & {
    children: React.ReactNode
  }
>

export { MediaContextProvider, Media, mediaStyle }
