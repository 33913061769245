import { getEnvVar } from '@mc/client-env'
import {
  optanonActiveGroups,
  userHasGivenGdprPermission,
  STRICTLY_NECESSARY,
  PERFORMANCE,
  TARGETTING,
} from '@mc/user-data'

import { FB_PIXEL_OPTIONS } from './constants'

async function fetchDestinationForWriteKey(writeKey: string) {
  const res = await fetch(
    `https://cdn.segment.com/v1/projects/${writeKey}/integrations`,
  )

  if (!res.ok) {
    throw new Error(
      `Failed to fetch integrations for write key ${writeKey}: HTTP ${res.status} ${res.statusText}`,
    )
  }

  const destinations = await res.json()

  return destinations as { name: string; category: string }[]
}

export default async function getDestinationPreferences() {
  const destinations = await fetchDestinationForWriteKey(
    getEnvVar('SEGMENT_API_KEY') as string,
  )

  const activeGroups = optanonActiveGroups()

  const userHasGivenUsPermission = userHasGivenGdprPermission()

  const destinationPreferences: Record<string, boolean | object> = {}
  destinations.forEach(({ name, category }) => {
    if (category === 'Tag Managers' || category === 'Advertising') {
      destinationPreferences[name] = activeGroups.includes(TARGETTING)
    } else {
      destinationPreferences[name] = activeGroups.includes(PERFORMANCE)
    }
  })

  // If FB pixel is enabled, pass LDU options
  if (destinationPreferences['On Call Facebook Pixel']) {
    destinationPreferences['On Call Facebook Pixel'] = FB_PIXEL_OPTIONS
  }

  const onlyStrictlyNecessary =
    activeGroups[0] === STRICTLY_NECESSARY && activeGroups.length === 1

  return {
    userHasGivenUsPermission,
    destinationPreferences,
    onlyStrictlyNecessary,
  }
}
