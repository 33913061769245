import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { getEnvVar } from '@mc/client-env'
import { usePersonaTrack } from '@mc/persona-client'
import { useBucket } from '@mc/experiments-react'

import { FB_PIXEL_OPTIONS } from './constants'

const Segment = ({
  analyticsLabel,
  analyticsIntegrations,
}: {
  analyticsLabel?: string
  analyticsIntegrations?: Record<string, boolean>
}) => {
  const isCCPA = useBucket('ccpa') === 'on'
  const isGDPR = useBucket('gdpr') === 'on'
  const shouldAutoLoadAnalytics = !isCCPA && !isGDPR
  const { trackPage, loaded } = usePersonaTrack()

  const { asPath } = useRouter()
  const referrerRef = useRef<string>()
  const isHotJarEnabled = useBucket('enable_hotjar_legal_restrictions') === 'on'

  useEffect(() => {
    // load analytics for non ccpa/gdpr users
    if (shouldAutoLoadAnalytics && window.analytics?.load) {
      window.analytics.load(getEnvVar('SEGMENT_API_KEY') as string, {
        integrations: {
          'On Call Facebook Pixel': {
            dataProcessingOptions: FB_PIXEL_OPTIONS,
          },
          Hotjar: isHotJarEnabled,
        },
      })
    }
  }, [isHotJarEnabled, shouldAutoLoadAnalytics])

  useEffect(() => {
    if (loaded) {
      const referrer = referrerRef.current
      trackPage(
        analyticsLabel,
        {
          platform: 'next',
          // Referrer from this file works for client side navigation
          ...(referrer && { referrer }),
        },
        { integrations: analyticsIntegrations },
      )

      // set referrer for next route change
      referrerRef.current = window.location.href
    }
  }, [analyticsIntegrations, analyticsLabel, asPath, trackPage, loaded])

  return getEnvVar('SEGMENT_ENABLED') ? (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${getEnvVar(
            'SEGMENT_API_KEY',
          )}";;analytics.SNIPPET_VERSION="4.15.3";}}();`,
        }}
      />
    </Head>
  ) : null
}

Segment.propTypes = {
  analyticsLabel: PropTypes.string,
  analyticsIntegrations: PropTypes.objectOf(PropTypes.bool),
}

export default Segment
