let clientEnv: Record<string, unknown> = {}

declare global {
  interface Window {
    clientEnv?: Record<string, unknown>
  }
}

export const getClientEnv = function () {
  return clientEnv
}

export const setClientEnv = function (envProps: Record<string, unknown> = {}) {
  clientEnv = envProps
}

// automatically set client env from rails
// This usage of `window` cannot be correctly verified by the eslint rule
// ssr-friendly/no-dom-globals-in-module-scope. :(
if (typeof window !== 'undefined' && window?.clientEnv) {
  setClientEnv(window.clientEnv)
}
