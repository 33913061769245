/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconLogoDefault({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <path
          fill='currentColor'
          d='M11.87 12.47 10.145 6.5H5.361v.959h.454c.567 0 1.036.33 1.208.942l2.635 9.098h2.4l1.177-4.039-.017-.014c-.768 0-1.145-.283-1.348-.975M21.546 16.54c-.565 0-1.02-.376-1.191-.958L17.72 6.5h-3.67l3.182 10.999H22v-.959zM2 16.538v.962h4.24v-.962z'
        />
      </svg>
    </>
  )
}
IconLogoDefault.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconLogoDefault
