import { UserService, UserCartDataService, User, CartData } from '@mc/api'

export interface Subscription {
  id?: number
  paymentGatewayUserProduct?: object | null
}

export type Account = (User & CartData) | Record<string, never>

let accountProperties: Account | undefined

export const getAccountPropertiesAsync = async (
  refresh = false,
  deep = false,
): Promise<Account> => {
  if (!!accountProperties && !refresh) {
    return Promise.resolve(accountProperties)
  }

  const userPromise = UserService.getUser({ id: 'current', deep })
  const userInfoPromise = UserCartDataService.getUserCartData({ deep: true })

  const [user, userInfo] = await Promise.allSettled([
    userPromise,
    userInfoPromise,
  ])

  accountProperties = {}

  if (user.status === 'fulfilled' && userInfo.status === 'fulfilled') {
    accountProperties = { ...user.value, ...userInfo.value }
  }

  return accountProperties
}

export const resetAccountProperties = () => {
  accountProperties = undefined
}
