import { SITE_URL } from 'libs/constants'

export const removeQueryParams = (str: string) =>
  str.split('?')[0].split('#')[0]

export const getUrlWithoutQueryParams = (path: string) =>
  `${SITE_URL}${removeQueryParams(path)}`

type Params = Array<[string, string]>
export const buildQueryParams = (params: Params) =>
  params.map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&')
