import { camelCase, mapKeys } from 'lodash'

type CamelCase<S extends string> =
  S extends `${infer P1}_${infer P2}${infer P3}`
    ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
    : S

export type KeysToCamelCase<T> = {
  [K in keyof T as CamelCase<string & K>]: T[K]
}

export const convertKeysToCamelCase = <T extends object>(
  obj: T,
): KeysToCamelCase<T> =>
  mapKeys(obj, (_, k) => camelCase(k)) as KeysToCamelCase<T>
