const PREFIX = '/api/persona/v2'

export const Auth0Service = {
  login: async ({
    email,
    password,
    returnTo,
  }: {
    email: string
    password: string
    returnTo: string
  }) => {
    try {
      const response = await fetch(`${PREFIX}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: email,
          password,
          returnTo,
        }),
      })

      const body = await response.json()

      if (!response.ok) {
        throw new Error(body.error)
      }
      return body
    } catch (err) {
      throw new Error(err as string)
    }
  },
  userInfo: async () => {
    try {
      const response = await fetch(`${PREFIX}/auth/me`, {
        method: 'GET',
        headers: {
          'Cache-Control':
            'no-store, no-cache, must-revalidate, proxy-revalidate',
        },
      })

      const body = await response.json()

      if (!response.ok) {
        throw new Error(body.error)
      }

      return body
    } catch (err) {
      throw new Error(err as string)
    }
  },
  logout: async () => {
    try {
      const response = await fetch(`${PREFIX}/auth/logout`, {
        method: 'POST',
      })

      const body = await response.json()

      if (!response.ok) {
        throw new Error(body.error)
      }

      return body
    } catch (err) {
      throw new Error(err as string)
    }
  },
  forgotPassword: async ({ email }: { email: string }) => {
    try {
      const response = await fetch(`${PREFIX}/auth/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })

      const body = await response.json()

      if (!response.ok) {
        throw new Error(body.error)
      }

      return body
    } catch (err) {
      throw new Error(err as string)
    }
  },
  signup: async ({
    email,
    password,
    name,
    gdpr,
  }: {
    email: string
    password: string
    name: string
    gdpr: boolean
  }) => {
    let response = null
    try {
      response = await fetch('/api/persona/v2/auth/sign-up', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
          gdpr,
          name,
        }),
      })

      const body = await response.json()

      if (!response.ok) {
        throw new Error(body.error?.message as string)
      }

      return body
    } catch (err) {
      throw new Error(err as string)
    }
  },
}
