import { Resource } from 'i18next'
import { getGlobalI18nInstance, I18nDocument } from './handleI18nInstance'

const globalInstance = getGlobalI18nInstance()

interface RegisterI18nResourcesOptions {
  namespace: string
  resources: { [locale: string]: I18nDocument | Resource }
}

export const registerI18nResources: ({
  namespace,
  resources, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: RegisterI18nResourcesOptions) => any = ({
  namespace,
  resources,
}: RegisterI18nResourcesOptions) => {
  Object.keys(resources).forEach((locale) => {
    if (!globalInstance.hasResourceBundle(locale, namespace)) {
      globalInstance.addResourceBundle(locale, namespace, resources[locale])
    }
  })

  return globalInstance
}
