import { onCLS, onLCP, onFID, onFCP, onTTFB } from 'web-vitals'
import { onINP } from 'web-vitals/attribution'
import type { Metric, INPMetricWithAttribution } from 'web-vitals'

import { log } from '../utils/log'

type MetricData = {
  name: string
  value: number
  [k: string]: string | number | undefined
}

type TrackCallback = (metric: MetricData) => void

// NOTE: this is not my cup-o-tea, but it's the only way I can see using our standard trackEvent
// method without causing a circular dependency between this and @mc/track-event - JJ
export const metrics = (label: string, track: TrackCallback) => {
  ;[onCLS, onLCP, onFID, onINP, onFCP, onTTFB].forEach((event) => {
    event((rawMetric) => {
      const metric = buildMetricLog(rawMetric)
      log('performance', { ...metric, label })
      track(metric)
    })
  })
}

const buildMetricLog = (metric: Metric) => {
  let metricData: MetricData = {
    name: metric.name,
    value: metric.value,
  }

  if (metric.name === 'INP') {
    const inpMetric = metric as INPMetricWithAttribution
    const eventEntry = inpMetric?.attribution?.eventEntry
    metricData = {
      ...metricData,
      inp_event_target: inpMetric?.attribution?.eventTarget,
      inp_event_type: inpMetric?.attribution?.eventType,
      inp_load_state: inpMetric?.attribution?.loadState,
      inp_start_time: eventEntry?.startTime,
      inp_processing_start_time: eventEntry?.processingStart,
      inp_processing_end_time: eventEntry?.processingEnd,
      inp_duration: eventEntry?.duration,
      inp_interaction_id: eventEntry?.interactionId,
    }
    return metricData
  }

  return metricData
}
