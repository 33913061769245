import type NewRelicBrowser from 'new-relic-browser'

declare global {
  interface Window {
    newrelic?: typeof NewRelicBrowser
  }
}

export const trackPage = (label: string) => {
  window.newrelic?.setPageViewName(label)
}

export const trackEvent = (event: string, data: string | number) => {
  window.newrelic?.interaction().setAttribute(event, data)
  window.newrelic?.addPageAction(event, { data })
}

export const pageTransitionStart = () => {}

export const pageTransitionEnd = (
  label: string,
  measurement: PerformanceMeasure,
) => {
  const value = measurement.duration
  window.newrelic?.addPageAction('PageTransition', {
    label,
    value,
  })
}
