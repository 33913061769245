import type { NextApiRequest, NextApiResponse } from 'next'
import { ParsedUrlQuery } from 'querystring'
import Cookie from 'cookie-universal'
import { ONE_DAY } from 'libs/constants'
import { IncomingMessage, ServerResponse } from 'http'
import { GEO_COOKIE_KEY, REGION_COOKIE_KEY } from './constants'

const initGeoService = (
  req: Pick<NextApiRequest, 'headers'> | IncomingMessage | undefined,
  res:
    | Pick<NextApiResponse, 'getHeaders'>
    | ServerResponse<IncomingMessage>
    | undefined,
  router: { query?: ParsedUrlQuery },
): { region: string; geo: string } => {
  const cookies = Cookie(req, res)

  const geoDecider = ({
    headerKey,
    queryKey,
    cookieKey,
    fallback,
  }: {
    headerKey: string
    queryKey: string
    cookieKey: string
    fallback: string
  }) => {
    const cookieOverride = cookies.get(cookieKey)
    const queryOverride = router.query?.[queryKey]
    const header = req && 'headers' in req ? req.headers[headerKey] : undefined

    if (queryOverride) {
      cookies.set(cookieKey, queryOverride, {
        maxAge: ONE_DAY,
        path: '/',
      })
    }

    return queryOverride || cookieOverride || header || fallback
  }

  const geo = geoDecider({
    queryKey: 'geo',
    cookieKey: GEO_COOKIE_KEY,
    headerKey: 'cf-ipcountry',
    fallback: 'XX',
  })

  const region = geoDecider({
    queryKey: 'region',
    cookieKey: REGION_COOKIE_KEY,
    headerKey: 'region-isocode',
    fallback: 'XX',
  })

  return {
    region,
    geo,
  }
}

export default initGeoService
