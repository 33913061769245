import React from 'react'
import PropTypes from 'prop-types'
import ErrorBoundary from '@mc/error-boundary'

import { useExperiments } from './ExperimentContext'
import ExperimentVisible from './ExperimentVisible'
import ExperimentTracker from './ExperimentTracker'
import ExperimentLoader from './ExperimentLoader'

const Experiment = ({
  track = true,
  lazyload = false,
  defaultBucket = 'control',
  ...props
}) => {
  const { experiments, experimentsVariables } = useExperiments()
  const { name } = props
  const bucket = experiments?.[name] || defaultBucket
  const variables = experimentsVariables?.[name] || {}

  const newProps = {
    ...props,
    bucket,
    variables,
  }

  let content = <ExperimentLoader {...newProps} />

  if (lazyload) {
    content = <ExperimentVisible {...newProps}>{content}</ExperimentVisible>
  }

  if (track) {
    content = <ExperimentTracker {...newProps}>{content}</ExperimentTracker>
  }

  return <ErrorBoundary>{content}</ErrorBoundary>
}

Experiment.propTypes = {
  name: PropTypes.string.isRequired,
  lazyload: PropTypes.bool,
  track: PropTypes.bool,
  defaultBucket: PropTypes.string,
}

export default Experiment
