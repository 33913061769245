import { useContext } from 'react'
import { Auth0UserContext } from '../components/Auth0UserProvider/Auth0UserProvider'

export const useAuth0User = () => {
  const result = useContext(Auth0UserContext)

  if (!result) {
    throw new Error('useAuth0User must be used within an Auth0UserProvider')
  }

  return result
}
