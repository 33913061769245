import { type Variants } from '@mc/experiments'

import { log } from '../utils/log'
import * as nr from '../adapters/newrelic'

export const experiment = (
  name: string,
  bucket: Variants | null | undefined,
) => {
  if (!bucket) return

  log('experiment', { [name]: bucket })
  nr.trackEvent(`experiment-${name}`, bucket)
}
