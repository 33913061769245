import { useBucket } from '@mc/experiments-react'
import { trackExperimentViewed } from '@mc/track-event'
import { useEffect } from 'react'

// Growthbook experiment: https://app.growthbook.io/features/dais-1128_v1
export const AI_PERSONA_MVP_EXPERIMENT_FLAG = 'dais-1128_v1'

export const useIsAiPersonaMVPEnabled = () => {
  const bucket = useBucket<'control' | 'variant_1'>(
    AI_PERSONA_MVP_EXPERIMENT_FLAG,
  )

  useEffect(() => {
    if (bucket)
      trackExperimentViewed(
        AI_PERSONA_MVP_EXPERIMENT_FLAG,
        {},
        { trackUserLeap: true },
      )
  }, [bucket])

  return bucket === 'variant_1'
}
