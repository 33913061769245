import Cookies from 'js-cookie'
import createSessionId from './createSessionId'
import { getVisitSessionId } from './getVisitSessionId'
import {
  VISIT_TTL_MILLISECONDS,
  TRACK_VISIT_SESSION_COOKIE,
} from './visitSessionCookieConstants'

/**
 * Creates or updates the timestamp of the visit session id cookie
 * @returns The visit session id
 */
const createOrGetVisitSessionIdFromCookie = () => {
  const returnSessionId = getVisitSessionId() || createSessionId()

  const expires = new Date(new Date().getTime() + VISIT_TTL_MILLISECONDS)

  Cookies.set(TRACK_VISIT_SESSION_COOKIE, returnSessionId, { expires })

  return returnSessionId
}

export default createOrGetVisitSessionIdFromCookie
