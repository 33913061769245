declare global {
  interface Window {
    OptanonActiveGroups: string
  }
}

export const optanonActiveGroups = () => {
  const { OptanonActiveGroups } = window
  // first and last elements are empty strings, let's remove them
  return (OptanonActiveGroups || '').split(',').filter((group) => group.length)
}
