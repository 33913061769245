const SESSION_STORAGE_KEY = 'oncall_last_page_visited'

export const getLastPage = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(SESSION_STORAGE_KEY)
  }
  return null
}

export const setLastPage = () => {
  if (typeof window !== 'undefined') {
    const currentPath = window.location.pathname
    if (!currentPath.includes('/call')) {
      localStorage.setItem(SESSION_STORAGE_KEY, currentPath)
    }
  }
}
