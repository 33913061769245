import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { throttle } from 'lodash'

export default class ExperimentVisible extends PureComponent {
  static propTypes = {
    buffer: PropTypes.number,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
  }

  static defaultProps = {
    buffer: 0,
  }

  state = {
    inViewport: false,
  }

  container = React.createRef()

  componentDidMount = () => {
    this.bind()
  }

  componentWillUnmount() {
    this.unbind()
  }

  bind = () => {
    window.addEventListener('scroll', this.handleScroll)
    this.handleScroll() // component may be in view already
  }

  unbind = () => {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = throttle(() => {
    const { inViewport } = this.state

    if (!inViewport && this.isInViewport()) {
      this.setState({ inViewport: true })
      this.unbind()
    }
  }, 100)

  isInViewport = () => {
    if (!this.container.current) return false

    const { buffer } = this.props
    const { top } = this.container.current.getBoundingClientRect()

    return top >= 0 && top - buffer <= window.innerHeight
  }

  render() {
    const { inViewport } = this.state

    return (
      <Fragment>
        {!inViewport && <div ref={this.container} />}
        {inViewport && this.props.children}
      </Fragment>
    )
  }
}
