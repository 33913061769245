import { getAccountEmail, getAccountId, isLoggedIn } from '@mc/user-info'
import { SprigEvent } from '@sprig-technologies/sprig-browser'

export const EXPERIMENT_VARIANT_EXPOSURE = 'Experiment Variant Exposure'
export const EXPERIMENT_INTERACTED = 'Experiment Interacted'

export const userLeapIdentify = () => {
  if (!window.UserLeap || !isLoggedIn()) return
  window.UserLeap('setUserId', `${getAccountId()}`)
  window.UserLeap('setEmail', getAccountEmail())
}

export const userLeapLogOut = () => {
  if (!(window.UserLeap && window.UserLeap.logoutUser)) return
  window.UserLeap.logoutUser()
}

export const triggerULEvent = (eventName: string) => {
  if (!window.UserLeap) return
  window.UserLeap('track', eventName)
}

export const trackULExperimentVariantExposure = (experimentName: string) => {
  triggerULEvent(`${EXPERIMENT_VARIANT_EXPOSURE}: ${experimentName}`)
}

export const trackULExperimentInteracted = (experimentName: string) => {
  triggerULEvent(`${EXPERIMENT_INTERACTED}: ${experimentName}`)
}

export const addULSurveyListener = (
  eventName: SprigEvent,
  listener: SprigListener,
) => {
  if (!window.UserLeap) return
  window.UserLeap('addListener', eventName, listener)
}

export const removeULSurveyListener = (
  eventName: SprigEvent,
  listener: SprigListener,
) => {
  if (!window.UserLeap) return
  window.UserLeap('removeListener', eventName, listener)
}
