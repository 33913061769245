import Cookies, { ICookie } from 'cookie-universal'

import { getEnvVar } from '@mc/client-env'

import { type InteractedTopic } from '../types/topics'

const EMAIL_COOKIE_NAME = 'mc_persona_email'
const SELECTED_TIER_COOKIE_NAME = 'mc_persona_membership'
const PURCHASE_COMPLETED_COOKIE_NAME = 'mc_persona_purchase_completed'
const INTERACTED_TOPICS_COOKIE_NAME = 'mc_persona_interacted_topics'
const LAST_MARKETING_PRODUCT_VIEWED_COOKIE_NAME =
  'mc_persona_last_marketing_product_viewed'
export const AI_PERSONA_ID_SESSION_COOKIE_NAME = 'ai_persona_auth0_id_session'
export const AI_PERSONA_SESSION_COOKIE_NAME = 'ai_persona_auth0_session'
export const AI_PERSONA_REFRESH_TOKEN_COOKIE_NAME =
  'ai_persona_auth0_refresh_token'

export const setEmailCookie = (email: string, cookiesObj?: ICookie) => {
  const isSecure = getEnvVar('NODE_ENV') === 'production'
  const cookies = cookiesObj || Cookies()
  cookies.set(EMAIL_COOKIE_NAME, email, {
    sameSite: 'lax',
    secure: isSecure,
    path: '/',
  })
}

export const getEmailCookie = (cookiesObj?: ICookie) => {
  const cookies = cookiesObj || Cookies()
  return cookies.get(EMAIL_COOKIE_NAME) || null
}

export const removeEmailCookie = (cookiesObj?: ICookie) => {
  const cookies = cookiesObj || Cookies()
  cookies.remove(EMAIL_COOKIE_NAME)
}

export const setSelectedTierCookie = (id: string, cookiesObj?: ICookie) => {
  const isSecure = getEnvVar('NODE_ENV') === 'production'
  const cookies = cookiesObj || Cookies()
  cookies.set(SELECTED_TIER_COOKIE_NAME, id, {
    sameSite: 'lax',
    secure: isSecure,
    path: '/',
    // tier selected lasts only 1 day for experimentation changes
    maxAge: 86400,
  })
}

export const getSelectedTierCookie = (cookiesObj?: ICookie): string | null => {
  const cookies = cookiesObj || Cookies()
  return cookies.get(SELECTED_TIER_COOKIE_NAME) || null
}

export const removeSelectedTierCookie = (cookiesObj?: ICookie) => {
  const cookies = cookiesObj || Cookies()
  cookies.remove(SELECTED_TIER_COOKIE_NAME)
}

export const setPurchaseCompletedCookie = (
  subId: string,
  cookiesObj?: ICookie,
) => {
  const isSecure = getEnvVar('NODE_ENV') === 'production'
  const cookies = cookiesObj || Cookies()
  cookies.set(PURCHASE_COMPLETED_COOKIE_NAME, subId, {
    sameSite: 'lax',
    secure: isSecure,
    path: '/',
  })
}

export const getPurchaseCompletedCookie = (
  cookiesObj?: ICookie,
): string | null => {
  const cookies = cookiesObj || Cookies()
  return cookies.get(PURCHASE_COMPLETED_COOKIE_NAME) || null
}

export const removePurchaseCompletedCookie = (cookiesObj?: ICookie) => {
  const cookies = cookiesObj || Cookies()
  cookies.remove(PURCHASE_COMPLETED_COOKIE_NAME)
}

const findInteractedTopicIndex = (
  topics: InteractedTopic[],
  topic: InteractedTopic,
) => {
  if (topics.length) {
    return topics.findIndex(
      (t: InteractedTopic) =>
        t.topicUuid === topic.topicUuid &&
        t.instructorSlug === topic.instructorSlug,
    )
  }
  return -1
}

export const getInteractedTopicsCookie = (
  cookiesObj?: ICookie,
): InteractedTopic[] => {
  const cookies = cookiesObj || Cookies()
  const interactedTopics = cookies.get(INTERACTED_TOPICS_COOKIE_NAME)
  return interactedTopics || []
}

const removeInteractedTopic = (
  topics: InteractedTopic[],
  topic: InteractedTopic,
) => topics.filter((item) => item.topicUuid !== topic.topicUuid)

export const addInteractedTopicOnCookies = (
  topic: InteractedTopic,
  cookiesObj?: ICookie,
) => {
  const isSecure = getEnvVar('NODE_ENV') === 'production'
  const cookies = cookiesObj || Cookies()
  let interactedTopics = getInteractedTopicsCookie(cookies)
  const topicIndexFound = findInteractedTopicIndex(interactedTopics, topic)
  if (topicIndexFound === -1) {
    // If the topic is not found, add it to the top of the list
    interactedTopics.unshift(topic)
  } else {
    // If the topic is found, remove it and push it to the top of the list
    interactedTopics = removeInteractedTopic(interactedTopics, topic)
    interactedTopics.unshift(topic)
  }

  /* Ensure the array length does not exceed 60 (4KB limit gives us ~50 items limit)
     so if we cut the array, we are losing the least interacted topics and that won't be a problem
  */
  const MAX_INTERACTED_TOPICS = 50
  if (interactedTopics.length > MAX_INTERACTED_TOPICS) {
    interactedTopics = interactedTopics.slice(0, MAX_INTERACTED_TOPICS)
  }

  cookies.set(INTERACTED_TOPICS_COOKIE_NAME, interactedTopics, {
    sameSite: 'lax',
    secure: isSecure,
    path: '/',
  })
}

export const getLastMarketingProductViewedCookie = (
  cookiesObj?: ICookie,
): string | null => {
  const cookies = cookiesObj || Cookies()
  return cookies.get(LAST_MARKETING_PRODUCT_VIEWED_COOKIE_NAME) || null
}

export const setLastMarketingProductViewedCookie = (
  productId: string,
  cookiesObj?: ICookie,
) => {
  const isSecure = getEnvVar('NODE_ENV') === 'production'
  const cookies = cookiesObj || Cookies()
  cookies.set(LAST_MARKETING_PRODUCT_VIEWED_COOKIE_NAME, productId, {
    sameSite: 'lax',
    secure: isSecure,
    path: '/',
  })
}
