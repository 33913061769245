import React, { createContext } from 'react'

import { type Tier } from '../../types/checkout'

interface TierContextType {
  lowestTier?: Tier
  monthlyTier?: Tier
  annualTier?: Tier
}

const INITIAL_CONTEXT: TierContextType = {
  lowestTier: undefined,
  monthlyTier: undefined,
  annualTier: undefined,
}

const TierContext = createContext<TierContextType>(INITIAL_CONTEXT)

export const useTiers = () => {
  const ctx = React.useContext(TierContext)
  if (!ctx) {
    throw new Error('useTiers must be used within CheckoutProvider')
  }
  return ctx as TierContextType
}

export const parseTiers = (tiers: Tier[]) => {
  let lowestTier
  let monthlyTier
  let annualTier
  if (tiers.length > 0) {
    monthlyTier = tiers.find((t) => t.recurring.interval === 'month')
    annualTier = tiers.find((t) => t.recurring.interval === 'year')
    lowestTier = annualTier
  }
  return { lowestTier, monthlyTier, annualTier }
}

export const TierProvider = ({
  children,
  tiers = [],
}: {
  children: React.ReactNode
  tiers?: Tier[]
}) => {
  // Lowest tier is our best offer for the user on monthly basis, so it should be the annual tier cost per month
  const { lowestTier, monthlyTier, annualTier } = parseTiers(tiers)

  return (
    <TierContext.Provider
      value={{
        lowestTier,
        monthlyTier,
        annualTier,
      }}
    >
      {children}
    </TierContext.Provider>
  )
}
