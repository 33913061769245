export const paths = {
  root: '/',
  // account management routes
  settings: '/settings',
  // Auth & checkout routes
  personaAuth: (method: 'login' | 'logout' | 'me' | 'callback' | string) =>
    `/api/persona/auth/${method}`,
  personaCustomAuth: (
    method: 'login' | 'signup' | 'forgot-password' | string,
  ) => `/${method}`,
  // checkout routes
  personaCheckoutRoot: '/checkout',
  personaCheckoutSignUp: '/checkout/signup',
  personaCheckoutMembership: '/checkout/membership',
  personaCheckoutPayment: '/checkout/payment',
  personaCheckoutComplete: '/checkout/success',
  // help resources & legal routes
  // TODO: Move legal, faq, footer routes here
  helpCenter: '/help-center',
  requestDataDeletion:
    'https://privacy.masterclass.com/policies?modal=select-subject',
  homepage: '/homepage',
  // aimentor (persona) routes
  personaCall: (instructorSlug = ':instructorSlug') =>
    `/aimentor/${instructorSlug}/call`,
  personaLibrary: (instructorSlug = ':instructorSlug') =>
    `/library/${instructorSlug}`,
}
