import { getEnvVar } from '@mc/client-env'
import { featureFlipIsEnabled } from '@mc/feature-flip'

type Airgap = {
  ready: (cb: (airgap: Airgap) => void) => void
  ui: false
  readyQueue: ((airgap: Airgap) => void)[]
  getConsent?: () => { purposes?: { SaleOfInfo: boolean } } | undefined
}

declare global {
  interface Window {
    airgap?: Airgap
  }
}

let airgapAPI: Promise<Airgap>

function getAirgap() {
  if (airgapAPI) {
    return airgapAPI
  }

  airgapAPI = new Promise((resolve, reject) => {
    // Stub airgap.js ready queue
    if (!window.airgap?.ready) {
      window.airgap = {
        ui: false,
        readyQueue: [],
        ready(callback) {
          this.readyQueue.push(callback)
        },
        ...window.airgap,
      }
    }

    // Wait for airgap.js to be ready
    window.airgap?.ready((airgap) => {
      resolve(airgap)
    })

    const script = document.createElement('script')
    script.addEventListener('error', (evt) => {
      reject(evt)
    })

    // Load airgap.js script asynchronously
    script.async = true
    script.defer = true
    script.src = `https://transcend-cdn.com/cm/${getEnvVar(
      'AIRGAP_BUNDLE_ID',
    )}/airgap.js`
    document.documentElement.appendChild(script)
  })

  return airgapAPI
}

export async function loadConsentedSegment() {
  // Disable LDU mode by default
  let dataProcessingOptions: [string[], number, number] = [[], 0, 0]

  try {
    const airgap = await getAirgap()
    const isLDUConsented = airgap?.getConsent?.()?.purposes?.SaleOfInfo

    if (!isLDUConsented) {
      // If user opted out, enable LDU mode and force California geo so user information is not sold.
      // https://developers.facebook.com/docs/marketing-apis/data-processing-options/#data-processing-options-for-users-in-california
      // https://segment.com/docs/connections/destinations/catalog/facebook-pixel/#limited-data-use
      dataProcessingOptions = [['LDU'], 1, 1000]
    }
  } catch (e) {
    // TODO: Log to raygun or something
    // eslint-disable-next-line no-console
    console.error("Can't load Airgap: ", e)
  }

  if (window.analytics) {
    window.analytics.load(getEnvVar('SEGMENT_API_KEY') as string, {
      integrations: {
        'Facebook Pixel': { dataProcessingOptions },
        Hotjar: false, // Disable Hotjar in California due to legal reasons
      },
    })
  }
}

if (featureFlipIsEnabled('airgap_enabled')) {
  loadConsentedSegment()
}
