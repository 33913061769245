export const AD_PARTNERS = [
  'Amplitude',
  'Facebook Pixel',
  'On Call Google Tag Manager',
]

export const EventTypes = {
  EXPERIMENT_BUCKETED: 'Experiment Bucketed',
  EXPERIMENT_VIEWED: 'Experiment Viewed',
} as const

export const Locations = {} as const

export const Actions = {} as const

export const Pages = {} as const
